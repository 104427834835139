import { useAuthState } from "react-firebase-hooks/auth";
import { Toaster } from "react-hot-toast";

import Form from "./Components/Form/Form";
import Header from "./Components/Header/Header";
import Startup from "./Components/Startup/Startup";
import Register from "./Components/Register/Register";
import Intake from "./Components/Intake/Intake";
import Reset from "./Components/Reset/Reset";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { auth } from "./firebase-config";
import { useLayoutEffect, useRef, useState } from "react";
import { useGlobalContext } from "./context/store";
import { populateUser } from "./context/user/actions";
import { useEffect } from "react";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import NavigationBar from "./Components/Navigation/NavigationBar";
import NavigationItem from "./Components/Navigation/NavigationItem";
import Profile from "./Views/Profile/Profile";
import TaskOverview from "./Views/TaskOverview/TaskOverview";
import { themeChecker } from "./helpers/themeChecker";
import { switchTheme } from "./context/theme/actions";
import { NotFound } from "./Views/404/404.js";
import { Container as ModalContainer } from "react-modal-promise";
import { MediaPlayer } from "./Components/MediaPlayer/MediaPlayer";
import { Offline } from "./Views/Offline/Offline";
import Desktop from "./Views/Desktop/Desktop";
import { Device } from "./Logic/MatchMedia";

const AppRoutes = (props) => {
  const [state, dispatch] = useGlobalContext();
  const [authState, loading] = useAuthState(auth);
  const [device, setDeviceState] = useState({
    isMobile: Device.isMobile,
    isMobileViewPort: Device.isMobileViewPort,
    isPortrait: Device.isPortrait,
  });

  const location = useLocation();
  const [navBarVisible, setNavBarVisibility] = useState(false);

  const mediaPlayerControls = useRef();

  themeChecker.onChange = (theme) => {
    dispatch(switchTheme(theme));
  };

  function onResize() {
    setDeviceState({
      isMobile: Device.isMobile,
      isMobileViewPort: Device.isMobileViewPort,
      isPortrait: Device.isPortrait,
    });
  }

  useEffect(() => {
    window.dataLayer.push({ event_params: null });
    window.dataLayer.push({
      event: "pageview",
      page: {
        path: location.pathname,
      },
      user: {
        theme: window.localStorage.currentTheme || "",
        userId: state?.user?.profile?.uid,
        theme_settings: window.localStorage.autoTheme || "",
      },
    });
    const fullscreenLocations = [
      "/login",
      "/register",
      "/reset",
      "/intake",
      "/startup",
    ];
    setNavBarVisibility(!fullscreenLocations.includes(location.pathname));
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (loading && navigator.onLine) return;

    // if (!authState) return;

    dispatch(populateUser());
    if (!themeChecker.isActive) themeChecker.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, loading]);

  useLayoutEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  if (!device.isMobile)
    return <Desktop isMobileViewPort={device.isMobileViewPort}></Desktop>;
  // if (isMobile) {
  return (
    <div className={state.theme.theme}>
      <div className="App">
        <Offline>
          <Header></Header>
          <Routes>
            <Route path="/login" element={<Form />} />
            <Route path="/register" element={<Register />} />
            <Route path="/startup" element={<Startup />} />
            <Route exact path="/meditaties" element={<PrivateRoute />}>
              <Route
                exact
                path="/meditaties"
                element={
                  <TaskOverview
                    playerControls={mediaPlayerControls}
                    overviewType="audioActions"
                  />
                }
              />
            </Route>
            <Route exact path="/acties" element={<PrivateRoute />}>
              <Route
                exact
                path="/acties"
                element={<TaskOverview overviewType="allActions" />}
              />
            </Route>
            <Route exact path="/home" element={<PrivateRoute />}>
              <Route
                exact
                path="/home"
                element={<TaskOverview overviewType="myActions" />}
              />
            </Route>
            <Route path="/reset" element={<Reset />} />
            <Route exact path="/intake" element={<PrivateRoute />}>
              <Route exact path="/intake" element={<Intake />} />
            </Route>
            <Route exact path="/profiel" element={<PrivateRoute />}>
              <Route exact path="/profiel" element={<Profile />} />
              <Route exact path="/profiel/contact" element={<></>} />
              <Route exact path="/profiel/vragen" element={<></>} />
            </Route>
            <Route path="/" element={<Navigate to="/home" />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
          <ModalContainer></ModalContainer>
          <MediaPlayer api={mediaPlayerControls}></MediaPlayer>
          <NavigationBar visible={navBarVisible}>
            <NavigationItem
              to="/home"
              title="Mijn Acties"
              icon="myTasks"
            ></NavigationItem>
            <NavigationItem
              to="/acties"
              title="Alle Acties"
              icon="allTasks"
            ></NavigationItem>
            <NavigationItem
              to="/meditaties"
              title="Meditaties"
              icon="meditation"
            ></NavigationItem>
            <NavigationItem
              to="/profiel"
              title="Profiel"
              icon="profile"
            ></NavigationItem>
          </NavigationBar>
          <Toaster
            position="bottom-center"
            containerStyle={{
              bottom: 80,
              pointerEvents: "none",
            }}
            toastOptions={{
              duration: 4000,
              style: {
                borderRadius: 1000,
                pointerEvents: "none",
                fontSize: "var(--font-size)",
                boxShadow: "0 0 10px 0 rgba(0,0,0,0.3)",
                background: "var(--toast-background-color)",
                color: "var(--toast-color)",
              },
              iconTheme: {
                primary: "var(--toast-icon-color-primary)",
              },
            }}
          />
        </Offline>
      </div>
    </div>
  );
  // } else {
  //   return (
  //     <div className="Desktop">
  //       <Header></Header>
  //       <div className="Desktop__message">
  //         <div className="Desktop__message__klaasje">
  //           <Klaasje />
  //         </div>
  //         <div className="Desktop__message__copy">
  //           Voor een optimale gebruikerservaring <br /> verzoeken we u deze
  //           applicatie op een smartphone te openen.
  //         </div>
  //         <div className="Desktop__message__qr">
  //           <QrCode />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
};

export default AppRoutes;
