// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useGlobalContext } from "../../context/store";
import Loading from "../Loading/Loading";

const PrivateRoute = (props) => {
  const [state] = useGlobalContext();

  if (!state.user || state.user.loading)
    return <Loading isLoading={true}></Loading>;

  return state.user.profile ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
