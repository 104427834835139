import {
  SETTING_USER,
  SIGNING_IN_USER,
  SIGNING_IN_WTIH_GOOGLE_USER,
  SIGNING_IN_FAILED,
  SIGNED_OUT,
  SIGNING_OUT_USER,
  REGISTER_USER,
  REGISTERING_USER,
  REGISTERING_USER_FAILED,
  SET_PROFILE_DATA,
  SET_PROFILE_DATA_FAILED,
  VALIDATION_ERROR,
} from "./actions";
import initialState from "./state";

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGNING_IN_USER:
      return { loading: true };
    case SIGNING_IN_WTIH_GOOGLE_USER:
      return { loading: true };
    case SIGNING_OUT_USER:
      return { loading: true };
    case SIGNED_OUT:
      return { profile: payload, loading: false };
    case SETTING_USER:
      return { profile: payload, loading: false };
    case SIGNING_IN_FAILED:
      return { error: payload, loading: false };
    case REGISTER_USER:
      return { profile: payload, loading: false };
    case REGISTERING_USER:
      return { loading: true };
    case REGISTERING_USER_FAILED:
      return { error: payload, loading: false };
    case VALIDATION_ERROR:
      return { error: payload, loading: false };
    case SET_PROFILE_DATA:
      return { profile: payload, loading: false };
    case SET_PROFILE_DATA_FAILED:
      return { error: payload, loading: false };
    default:
      return state;
  }
};

export default reducer;
