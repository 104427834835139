import './Loading.scss';
import { useGlobalContext } from "../../context/store";
import LogoSubline from '../LogoSubline/LogoSubline';
import { ReactComponent as WavesSvg } from '../../assets/images/waveLines.svg'


function Loading(props) {
  const [state] = useGlobalContext();

  if (!state.user.loading && !props.isLoading) return (<></>);

  if (props.size === 'small') {
    return (
    <div className={"Loading Loading--small"}>
      <div className="Loading__content">
        <div className="Loading__spinner">Loading...</div>
        {props.size === 'small' && props.message &&
          <div className="Loading__content__message">{props.message}</div>
        }
      </div>
    </div>  
    )
  } else {
    return (
      <div className="Loading Loading--large"> 
        <div className="Loading__background Loading__background--top">
          <WavesSvg />
        </div>
        <div className="Loading__background Loading__background--bottom">
          <WavesSvg />
        </div>
        <div className='Loading__content'>
          <div className="Loading__logo">
            <LogoSubline colorMode={state.theme.theme} />
          </div>
          <div className="Loading__spinner">Loading...</div>
        </div>
        

        {/* <div className={`Loading__background loader__background--${state.theme.theme}`} >
          <div className="loader__background__top">
            <WavesSvg />
          </div>
          
          <div className="loader__background__gradient"></div>
        </div> */}
        
      </div>
    )
  }
}

export default Loading;
