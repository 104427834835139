import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Howl } from "howler";
import { getTimeFromSeconds } from "../../helpers/time-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePause,
  faCirclePlay,
  faRotateLeft,
  faRotateRight,
  faStopwatch,
} from "@fortawesome/free-solid-svg-icons";
import "./MediaPlayer.scss";
import { gsap, Power2 } from "gsap/all";
import * as Hammer from "hammerjs";
import TimePicker from "../TimePicker/TimePicker";
import { Timer } from "../../helpers/timer";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useOnNavigation } from "../../helpers/prompt-blocker";

const storage = getStorage();

async function getSourceArrayFromStoragePath(
  path,
  extensions = [".ogg", ".aac", ".mp3"]
) {
  const sourcePromises = [];
  extensions.forEach((ext) => {
    const pathRef = ref(storage, `${path}${ext}`);
    sourcePromises.push(getDownloadURL(pathRef));
  });

  const sourceArray = await Promise.all(sourcePromises);
  // console.log(sourceArray);
  return sourceArray;
}

function MediaPlayer({ api }) {
  const [loading, setLoading] = useState(true);
  const [isVisible, setVisibility] = useState(false);
  const [timePickerVisible, setTimePickerVisibility] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState({
    time: {
      minutes: 10,
      seconds: 0,
    },
  });
  const [playerOptions, setOptions] = useState({
    allowSeeking: false,
    allowJumping: true,
    useTimer: false,
    loop: false,
  });

  const [trackInfo, setTrackInfo] = useState({
    isPlaying: false,
    title: false,
    currentTime: false,
    duration: false,
  });

  const howlerRef = useRef();
  const playerContainerRef = useRef();
  const playerRef = useRef();
  const playerProgressRef = useRef();
  const playerProgressHandleRef = useRef();
  const playerControlRef = useRef();
  const timerRef = useRef();
  const timepickerRef = useRef();
  const fadeTimeoutRef = useRef();

  // Fires when the player starts playing content
  function onPlay() {
    // console.log('Audio playing');
    setTrackInfo((prevState) => {
      return { ...prevState, ...{ isPlaying: true } };
    });
    if (howlerRef.current) howlerRef.current.fade(0, 1, 1000);
    if (timerRef.current) timerRef.current.start();
    if (fadeTimeoutRef.current) {
      clearTimeout(fadeTimeoutRef.current);
      fadeTimeoutRef.current = null;
    }
    requestAnimationFrame(checkCurrentTime.bind(this));
  }

  // Fires when the player stops playing content
  function onPause() {
    // console.log("Audio paused");
    if (timerRef.current) timerRef.current.stop();
    setTrackInfo((prevState) => {
      return { ...prevState, ...{ isPlaying: false } };
    });
  }

  // Function to start pausing the player by fading the audio
  function invokePause(fadeDuration = 1000) {
    if (!howlerRef.current) return;
    howlerRef.current.fade(1, 0, fadeDuration);
    if (fadeTimeoutRef.current) {
      clearTimeout(fadeTimeoutRef.current);
      fadeTimeoutRef.current = null;
    }
    fadeTimeoutRef.current = setTimeout(() => {
      howlerRef.current.pause();
      howlerRef.current.volume(1);
    }, fadeDuration);
  }

  // Check the current time of the player on every animation frame
  function checkCurrentTime() {
    const self = this;
    const currentTime = self.seek() || 0;

    setIsSeeking((currentState) => {
      if (!currentState) onTimeUpdate(currentTime, self.duration());
      return currentState;
    });

    if (self.playing()) requestAnimationFrame(checkCurrentTime.bind(self));
  }

  // Fires when the user is actively seeking in the track
  function onSeekChange(e) {
    console.log("onSeekChange");
    const newX = e.center.x;
    const screenWidth = playerProgressRef.current.clientWidth;

    let location = newX / screenWidth;
    location = Math.min(1, location);
    location = Math.max(0, location);

    const newCurrentTime = location * howlerRef.current.duration();

    onTimeUpdate(newCurrentTime, howlerRef.current.duration());

    // console.log(newCurrentTime, location, trackInfo.duration);
    // howlerRef.current.seek(newCurrentTime);
    return false;
  }

  function onSeekStart(e) {
    setIsSeeking(true);
  }

  function onSeekEnd(e) {
    setIsSeeking(false);
    const newX = e.center.x;
    const screenWidth = playerProgressRef.current.clientWidth;

    let location = newX / screenWidth;
    location = Math.min(1, location);
    location = Math.max(0, location);

    const newCurrentTime = location * howlerRef.current.duration();

    onTimeUpdate(newCurrentTime, howlerRef.current.duration());

    howlerRef.current.seek(newCurrentTime);
    return false;
  }

  // Reverse the player by the amount of seconds provided
  function reverse(seconds) {
    const currentTime = howlerRef.current.seek();
    const newTime = currentTime - seconds;
    howlerRef.current.seek(newTime < 0 ? 0 : newTime);
    howlerRef.current.play();
  }

  // Advance the player by the amount of seconds provided
  function advance(seconds) {
    const currentTime = howlerRef.current.seek();
    const newTime = currentTime + seconds;
    howlerRef.current.seek(
      newTime > howlerRef.current.duration()
        ? howlerRef.current.duration()
        : newTime
    );
    howlerRef.current.play();
  }

  // Return the current track progress as a percentage (0-100)
  function getProgressInPercentage() {
    if (!trackInfo.duration || loading) return 0;
    return (trackInfo.currentTime / trackInfo.duration) * 100;
  }

  // Fires when the currentTime of the current track is updated
  function onTimeUpdate(currentTime, duration) {
    setTrackInfo((prevState) => {
      // console.log(prevState);
      return {
        ...prevState,
        currentTime,
        duration,
      };
    });
  }

  // Fires when a new track has finished loading
  function onSourceLoaded() {
    // console.log('Source loaded');
    setTrackInfo((prevState) => {
      return {
        ...prevState,
        currentTime: 0,
        duration: howlerRef.current.duration(),
      };
    });
    setLoading(false);
  }

  function bindPlayerFunctions(howler) {
    howler.on("load", onSourceLoaded);
    howler.on("play", onPlay.bind(howler));
    howler.on("pause", onPause);
  }

  // Set a track by providing an object containing at least the following information:
  /** @type{ (title: string, source: string | string[], options?: {loop?: boolean, useTimer?: boolean, allowSeeking?: boolean, allowJumping?: boolean}) => Promise<void>} */
  async function setTrack(title, source, options) {
    if (
      (Array.isArray(source) && source.includes(howlerRef?.current?._src)) ||
      (!Array.isArray(source) && howlerRef?.current?._src.indexOf(source) > -1)
    )
      return false;

    if (howlerRef.current) howlerRef.current.unload();
    setLoading(true);

    howlerRef.current = new Howl({
      src: source,
      autoplay: true,
      loop: options.loop || false,
    });

    if (options.useTimer) {
      createTimer(10);
    } else {
      if (timerRef.current) {
        timerRef.current.destroy();
        timerRef.current = null;
      }
      setTimePickerVisibility(false);
    }

    bindPlayerFunctions(howlerRef.current);

    // await setPlayerSource(track.src);
    setTrackInfo((prevState) => {
      return {
        ...prevState,
        title,
        isPlaying: false,
      };
    });

    setOptions((prevState) => {
      return {
        ...prevState,
        ...(options || {}),
      };
    });

    setVisibility(true);
    return true;
  }

  // Play and pause functions
  function play() {
    if (!howlerRef.current) return;
    howlerRef.current.play();
  }

  function pause() {
    if (!howlerRef.current) return;
    howlerRef.current.pause();
  }

  function stop() {
    if (!howlerRef.current) return;
    howlerRef.current.unload();
    howlerRef.current = undefined;
  }

  // Setup an api to allow external player controls
  api.current = {
    show: () => setVisibility(true),
    hide: () => {
      stop();
      setVisibility(false);
    },
    play,
    pause,
    stop,
    setTrack,
    setOptions: (options) => {
      setOptions((prevState) => {
        return { ...prevState, ...options };
      });
      howlerRef.current.loop(
        !options?.loop && !playerOptions.loop ? false : true
      );
    },
  };

  function onTimerClicked() {
    setTimePickerVisibility(false);
  }

  // Called when the timer's value was changed
  function onTimerChanged(value) {
    createTimer(value);
  }

  function createTimer(minutes) {
    if (timerRef.current) {
      timerRef.current.destroy();
      timerRef.current = null;
    }
    timerRef.current = new Timer(minutes * 60);
    timerRef.current.onTick = function (remaining) {
      setTimeRemaining(remaining);
    };
    timerRef.current.onComplete = function () {
      invokePause(3000);
      timerRef.current = null;
      // setTimeout(howlerRef.current.pause, 5000);
    };

    setTimeRemaining({
      totalSeconds: minutes * 60,
      time: {
        minutes,
        seconds: 0,
      },
    });

    // Hacky way to get the actual trackInfo state as this function gets the initial value for some reason.
    setTrackInfo((state) => {
      if (state.isPlaying) timerRef.current.start();
      return state;
    });
  }

  // Provide a small ripple animation when a button is touched
  function spawnClickFeedback(e) {
    const container = e.closest(".player__control__container");
    const element = document.createElement("div");
    element.classList.add("clickRipple");
    container.appendChild(element);

    gsap.fromTo(
      element,
      { scale: 0, autoAlpha: 1 },
      {
        duration: 0.3,
        scale: 1,
        autoAlpha: 0,
        onComplete: () => {
          element.remove();
        },
      }
    );
  }

  // Use effect. Called when player options change
  useEffect(() => {
    gsap.set(playerProgressRef.current, {
      autoAlpha: playerOptions.allowSeeking ? 1 : 0,
    });
    if (playerOptions.allowJumping) {
      playerControlRef.current.classList.remove("--no-jumping");
    } else {
      playerControlRef.current.classList.add("--no-jumping");
    }
  }, [playerOptions]);

  // Set the current height of the player as a CSS variable on every layout change
  useLayoutEffect(() => {
    document
      .querySelector(":root")
      .style.setProperty(
        "--mediaPlayerCurrentHeight",
        `${!isVisible ? 0 : playerContainerRef.current.clientHeight}px`
      );

    return () => {
      document
        .querySelector(":root")
        .style.setProperty("--mediaPlayerCurrentHeight", `0px`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use layout effect for when the player visibility changes. Only updates if the player or the visibility changes
  useLayoutEffect(() => {
    if (isVisible) {
      gsap.to(playerContainerRef.current, {
        duration: 0.3,
        y: 0,
        ease: Power2.easeOut,
      });
    } else {
      gsap.to(playerContainerRef.current, {
        duration: 0.3,
        y: "150%",
        ease: Power2.easeOut,
      });
    }

    if (timePickerVisible) {
      gsap.set(timepickerRef.current, { height: null });
      document
        .querySelector(":root")
        .style.setProperty(
          "--mediaPlayerCurrentHeight",
          `${!isVisible ? 0 : playerContainerRef.current.clientHeight}px`
        );
      gsap.from(timepickerRef.current, {
        height: 0,
        duration: 0.3,
        ease: "Power2.easeOut",
      });
    } else {
      gsap.to(timepickerRef.current, {
        height: 0,
        duration: 0.3,
        ease: "Power2.easeOut",
        onUpdate: () => {
          document
            .querySelector(":root")
            .style.setProperty(
              "--mediaPlayerCurrentHeight",
              `${!isVisible ? 0 : playerContainerRef.current.clientHeight}px`
            );
        },
      });
    }
  }, [playerProgressRef, isVisible, timePickerVisible]);

  // Use layout effect for setting up Hammer and setting the initial visibility for the player. Only called onComponentMount
  useLayoutEffect(() => {
    if (!isVisible) gsap.set(playerContainerRef.current, { y: "150%" });
    if (!timePickerVisible) gsap.set(timepickerRef.current, { height: 0 });

    var mc = new Hammer(playerProgressHandleRef.current);
    var panner = new Hammer.Pan();
    mc.add(panner);
    mc.on("pan", onSeekChange);
    mc.on("panstart", onSeekStart);
    mc.on("panend", onSeekEnd);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnNavigation(isVisible, async () => {
    setVisibility(false);
    stop();
    return true;
  });

  return (
    <div className="MediaPlayer" ref={playerContainerRef}>
      <div className="MediaPlayer__progress" ref={playerProgressRef}>
        <div
          className="MediaPlayer__progress__current"
          style={{ width: `${getProgressInPercentage()}%` }}
        >
          <div
            className="MediaPlayer__progress__current__handle"
            ref={playerProgressHandleRef}
            // onTouchStart={onStartSeeking}
            // onTouchEnd={onStopSeeking}
            // onDrag={onSeekChange}
          >
            <div className="MediaPlayer__progress__current__handle__dot"></div>
          </div>
        </div>
      </div>
      <div className="MediaPlayer__content">
        {loading && (
          <div className="MediaPlayer__content__loading">
            <div className="Loading__spinner"></div>
          </div>
        )}
        <div className="MediaPlayer__content__info">
          <p className="MediaPlayer__content__info__title">{trackInfo.title}</p>
          {playerOptions.useTimer && (
            <p className="MediaPlayer__content__info__time --timeLeft">
              {timeRemaining.time.minutes}m{timeRemaining.time.seconds}s
            </p>
          )}
          {!playerOptions.useTimer && (
            <p className="MediaPlayer__content__info__time">
              {getTimeFromSeconds(trackInfo.currentTime)} /{" "}
              {getTimeFromSeconds(trackInfo.duration)}
            </p>
          )}
        </div>
        <div className="MediaPlayer__content__controls" ref={playerControlRef}>
          {playerOptions.useTimer && (
            <div className="player__control__container">
              <FontAwesomeIcon
                className="player__control player__control--timer"
                icon={faStopwatch}
                onClick={(e) => {
                  setTimePickerVisibility(!timePickerVisible);
                  spawnClickFeedback(e.target);
                }}
              />
            </div>
          )}

          {playerOptions.allowJumping && (
            <div className="player__control__container">
              <FontAwesomeIcon
                className="player__control player__control--reverse"
                icon={faRotateLeft}
                onClick={(e) => {
                  reverse(10);
                  spawnClickFeedback(e.target);
                }}
              />
            </div>
          )}

          <div className="player__control__container">
            <FontAwesomeIcon
              className="player__control player__control--play"
              icon={trackInfo.isPlaying ? faCirclePause : faCirclePlay}
              onClick={(e) => {
                spawnClickFeedback(e.target);
                trackInfo.isPlaying
                  ? invokePause(300)
                  : howlerRef.current.play();
              }}
            />
          </div>

          {playerOptions.allowJumping && (
            <div className="player__control__container">
              <FontAwesomeIcon
                className="player__control player__control--forwards"
                icon={faRotateRight}
                onClick={(e) => {
                  advance(10);
                  spawnClickFeedback(e.target);
                }}
              />
            </div>
          )}
        </div>
      </div>

      <audio
        ref={playerRef}
        onLoadedData={onSourceLoaded}
        onPlay={onPlay}
        onPause={onPause}
        onTimeUpdate={onTimeUpdate}
      />
      <TimePicker
        value={timeRemaining.time.minutes}
        onChange={onTimerChanged}
        onClick={onTimerClicked}
        forwardRef={timepickerRef}
      ></TimePicker>
    </div>
  );
}

export { MediaPlayer, getSourceArrayFromStoragePath };
