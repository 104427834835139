import "./App.scss";

import React from "react";

import Store from "./context/store";
import AppRoutes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-KGWMDNX",
};
TagManager.initialize(tagManagerArgs);
function App() {
  return (
    <Store>
      <Router>
        <AppRoutes></AppRoutes>
      </Router>
    </Store>
  );
}

export default App;
