import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import { useGlobalContext } from "../../context/store";
import "./TaskOverview.scss";
import { React, useEffect, useState } from "react";
import Klaasje from "../../Components/Klaasje/Klaasje";
import ItemContainer from "../../Components/ItemContainer/ItemContainer";
import ActionModal from "../../Components/ActionModal/ActionModal";
import { Tasks } from "../../Logic/Tasks";
import { Events } from "../../Logic/Events";
import { useNavigate } from "react-router-dom";
import { findAndReplace } from "../../helpers/propertyReplacer";
import Toggle from "../../Components/Toggle/Toggle";
import { getSourceArrayFromStoragePath } from "../../Components/MediaPlayer/MediaPlayer";
import { useRef } from "react";
import { NotificationService } from "../../ServiceWorkers/serviceWorkerLoader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase-config";
import { NotificationModal } from "../Tos/Tos";
import { toast } from "react-hot-toast";

import addToHomeModal from "../../Components/HomeModal/HomeModal";
import { localStorage } from "../../context/localStorage/localStorage";

function TaskOverview(props) {
  const [playerIsOpen, setPlayerIsOpen] = useState(false);
  const [audioSubType, setAudioSubType] = useState("sleepSounds");
  const [homeModalExists, setHomeModalExists] = useState(false);
  const containerRef = useRef();

  const resetButtons = () => {
    const allButtons = containerRef.current.querySelectorAll(".Button");
    allButtons.forEach((button) => {
      button.classList.remove("Button--grey");
      button.classList.add("Button--orange");
    });
  };

  const toggleSwitch = (toggleValue) => {
    if (toggleValue === "slaapgeluiden") {
      setAudioSubType("sleepSounds");
    } else {
      setAudioSubType("meditation");
    }
  };

  async function openMediaPlayer(title, source, playerOptions) {
    window.dataLayer.push({ event_params: null });
    window.dataLayer.push({
      event: "interaction",
      event_params: {
        event_name: "start_meditation",
        task_name: title,
      },
    });
    const sourceArray = await getSourceArrayFromStoragePath(source);

    props.playerControls.current.setTrack(title, sourceArray, playerOptions);
  }
  const navigate = useNavigate();
  const [state] = useGlobalContext();
  const [openedTask, setOpenedTask] = useState();
  const [allTasks, setAllTasks] = useState([]);
  const [allEvents, setAllEvents] = useState({});
  const [closedTask, setClosedTask] = useState();
  const [modalShowing, setModalShowing] = useState(false);
  const [domains] = useState(["food", "bedroom", "mental", "sleep"]);
  const [sleepSounds] = useState(["nature", "binaural", "noise"]);
  const [meditationSounds] = useState(["visualisation", "bodyscan"]);
  const [goals, setGoals] = useState([]);
  const [taskOverview, setTaskOverview] = useState([]);
  let allEventsChecked = false;
  const [tasksHaveBeenSet, setTasksHaveBeenSet] = useState(false);
  const [overviewType] = useState();
  const [mainText, setmainText] = useState({});
  const [populated, setPopulated] = useState(false);
  const [taskFilter, setTaskFilter] = useState({});
  const [closedTasks, setClosedTasks] = useState(0);
  const [activeTasks, setActiveTasks] = useState(0);
  const [inActiveTasks, setinActiveTasks] = useState(0);
  const [notificationModal, setNotificationModal] = useState(false);

  const [status, setStatus] = useState("default");
  const populateTasks = async () => {
    if (allTasks.length < 1) {
      return Tasks.get();
    } else {
      return allTasks;
    }
  };

  const getAllTasks = async (events) => {
    await populateTasks().then(async (tasks) => {
      await Promise.all(
        tasks.map(async (task) => {
          task.isActive = false;
          goals.forEach((goal) => {
            if (task.goal.indexOf(goal) > -1) {
              task.isActive = true;
            } else {
              task.isActive = task.isActive ? task.isActive : false;
            }
          });

          if (events && events[task._id]) {
            for (const prop in events[task._id])
              task[prop] = events[task._id][prop];
            return task;
          } else {
            return task;
          }
        })
      ).then(async (tasks) => {
        let taskCopy = tasks;
        tasks = Tasks.set(tasks);

        setinActiveTasks(
          taskCopy.filter({
            isActive: false,
            isCompleted: false,
            type: "task",
          }).length
        );

        setActiveTasks(
          taskCopy.filter({
            isActive: true,
            isCompleted: false,
            type: "task",
          }).length
        );

        setAllTasks(tasks.filter());
        setTasksHaveBeenSet(true);
      });
    });
  };

  const filterTasks = (filterTerm) => {
    if (allTasks.length < 1) return;
    return allTasks.filter(filterTerm);
  };

  const getTranslation = (domain) => {
    switch (domain) {
      case "food":
        return "Eten, Drinken & Bewegen";

      case "mental":
        return "Mentale Rust";

      case "bedroom":
        return "Slaapkamer";

      case "sleep":
        return "Slaapgedrag";

      case "nature":
        return "Natuurgeluiden";

      case "binaural":
        return "Binaural audio";

      case "noise":
        return "White noise";

      case "visualisation":
        return "Visualisatie";

      case "fact":
        return "feitje";

      case "action":
        return "opdrachtencyclus";

      case "health":
        return "Ik wil mijn levensstijl verbeteren";

      case "energy":
        return "Meer energie en concentratie overdag";

      case "calmerNights":
        return "Rustigere nachten";

      default:
        return domain;
    }
  };

  const populateDomains = async () => {
    setPopulated(false);

    let newTasks = [];
    if (props.overviewType !== "audioActions") {
      domains.forEach((domain) => {
        let filter = taskFilter;
        filter.domain = domain;
        if (filterTasks()) {
          newTasks.push({
            domain: domain,
            tasks: [...filterTasks(filter)],
          });
        }

        setTaskOverview(newTasks);
        setPopulated(true);
      });
    } else {
      let categories;
      if (audioSubType === "sleepSounds") {
        categories = sleepSounds;
      } else {
        categories = meditationSounds;
      }
      categories.forEach((domain) => {
        let filter = taskFilter;
        filter.subType = audioSubType;
        filter.domain = domain;
        const filteredTasks = filterTasks(filter);
        if (filteredTasks) {
          newTasks.push({
            domain: domain,
            tasks: [...filteredTasks.sort("ASC")],
          });
          setTaskOverview([...newTasks]);
        } else {
          newTasks.push({
            domain: domain,
            tasks: [],
          });
          setTaskOverview([...newTasks]);
        }
      });
    }
  };

  const generateDomains = (taskOverview) => {
    const allDomains = [];
    let noTasks = 0;
    taskOverview.forEach((taskDomain, index) => {
      if (taskDomain.tasks.length > 0) {
        allDomains.push(
          <ItemContainer
            overviewType={props.overviewType}
            title={taskDomain.domain}
            tasks={taskDomain.tasks}
            openModal={openModal}
            openMediaPlayer={openMediaPlayer}
            key={`itemcontainer-${index}-${props.overviewType}`}
            keyProp={`itemcontainer-${index}-${props.overviewType}`}
            closeModal={closeModal}
            setClosedTask={setClosedTasks}
            closedTasks={closedTasks}
            resetButtons={resetButtons}
          />
        );
      } else {
        noTasks += 1;
      }
    });
    if (noTasks === domains.length && allEventsChecked) {
      return (
        <ContentBlock
          copy={
            'alle taken zijn afgerond of staan al in "mijn acties", goed bezig!'
          }
        />
      );
    } else {
      return allDomains;
    }
  };

  const getEvents = () => {
    Events.get().then(async (events) => {
      setAllEvents({ ...allEvents, ...events });
      getAllTasks(events);
    });
  };

  const getDutchPlural = (interval) => {
    switch (interval) {
      case "daily":
        return "dagen";
      case "weekly":
        return "weken";
      case "monthly":
        return "maanden";
      default:
        return {};
    }
  };

  useEffect(() => {
    if (!state.user.profile) {
      navigate("/login");
    }
    if (!state.user.profile.intakeData) {
      navigate("/intake");
    } else {
      setGoals(state.user.profile.intakeData.sleepGoals);
    }

    if (
      (allTasks.length > 0 && goals.length > 0) ||
      taskOverview.length === 0
    ) {
      //if there are tasks
      populateDomains();
    } else {
      setTaskOverview([]);
    }

    if (Object.keys(allEvents).length < 1 && !allEventsChecked) {
      allEventsChecked = true;
      getEvents();
    } else if (
      state.user.profile.events &&
      Object.keys(state.user.profile.events).length > 0 &&
      allTasks.length > 0
    ) {
      setAllEvents(state.user.profile.events);
      getAllTasks(state.user.profile.events);
      populateDomains();
    }
  }, [
    tasksHaveBeenSet,
    state.user.profile,
    state.user.profile.intakeData,
    closedTask,
    state.user,
    goals,
    modalShowing,
    overviewType,
    props,
    taskFilter,
    closedTasks,
  ]);

  const getNotificationModal = () => {
    NotificationModal().then(() => {
      Notification.requestPermission().then((result) => {
        if (result === "granted") {
          setStatus("granted");
          NotificationService.register(state.user.profile.uid);
          toast.success("Notificaties toegestaan!");
        } else if (result === "denied") {
          setStatus("denied");
          toast.error("Notificaties afgekeurd!");
        }
      });
    });
  };

  useEffect(() => {
    if ("Notification" in window) {
      if (!notificationModal && Notification.permission === "default") {
        setNotificationModal(true);
        getNotificationModal();
      }
    } else {
      const platform = window.navigator.platform;
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        const iOSIsInstalled = window.navigator.standalone === true;
        if (iOSIsInstalled && "Notification" in window) {
          setNotificationModal(true);
          getNotificationModal();
        } else if (
          !homeModalExists &&
          (!localStorage.homeScreenModal.get() ||
            localStorage.homeScreenModal.get() === "false")
        ) {
          setHomeModalExists(true);
          addToHomeModal().then(() => {
            localStorage.homeScreenModal.set(true);
          });
        }
      }
    }
    switch (props.overviewType) {
      case "myActions":
        setTaskFilter({
          isCompleted: false,
          type: "task",
          isActive: true,
          // goal: (taskGoals) => {
          //   if (!goals) return false;
          //   var taskValid = goals.find((goal) => {
          //     return taskGoals.indexOf(goal) > -1;
          //   });/T03TP2JLV-UN0UTEJRL-8b2a31426ce0-72

          //   return taskValid || false;
          // },
        });

        if (activeTasks) {
          goals.forEach((goal) => {
            getTranslation(goal);
          });

          setmainText({
            title: findAndReplace(`{{name.first}}'s acties`, state, {
              capitalize: "sentence",
            }),
            copy: ["De volgende acties voor je doelen staan voor je klaar:"],
          });
        } else {
          setmainText({
            title: findAndReplace(`{{name.first}}'s acties`, state, {
              capitalize: "sentence",
            }),
            copy: [
              "Goed bezig, je hebt al je acties voltooid! Kies een nieuw doel in je   <strong key='strongCopy1'>Profiel</strong> of voeg extra taken toe in <strong key='strongCopy2'>Alle acties</strong>.",
            ],
          });
        }

        break;
      case "allActions":
        setTaskFilter({
          type: "task",
          isCompleted: false,
          isActive: false,
        });

        if (inActiveTasks) {
          setmainText({
            title: findAndReplace(`Alle beschikbare acties`, state, {
              capitalize: "sentence",
            }),
            copy: "Zo jij hebt er zin in!",
          });
        } else {
          setmainText({
            title: findAndReplace(`Alle beschikbare acties`, state, {
              capitalize: "sentence",
            }),
            copy: [
              "Alle acties zijn afgerond of zitten in <strong key='strongCopy'>Mijn acties</strong>.",
            ],
          });
        }

        break;
      case "audioActions":
        setTaskFilter({
          type: "audio",
        });
        setmainText({
          title: "Mentale rust",
          copy: "Kom voor het slapen tot rust met een selectie meditatie oefeningen en slaapgeluiden.",
        });
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    overviewType,
    props.overviewType,
    audioSubType,
    activeTasks,
    notificationModal,
    homeModalExists,
  ]);

  /** @type {{
   *  type: 'task'|'audio',
   *  subType: 'tip'|'fact'|'action'|'sleepSounds'|'meditation',
   *  category: 'food'|'exercise'|'bedroom'|'mental'|'sleep'|'nature'|'binaural'|'noise',
   *  goal: 'calmerNights'|'health'|'energy'|false,
   *  duration: number|false,
   *  interval: 'daily'|'weekly'|'monthly'|false,
   *  importance: number | false,
   *  condition: {
   *    when?: string,
   *  } | false,
   *  content: {
   *    title: string,
   *    excerpt: string|false,
   *    body: string|false,
   *  },
   *  audioRef?: any|false,
   * }}
   */

  const openModal = (task) => {
    window.dataLayer.push({ event_params: null });
    window.dataLayer.push({
      event: "interaction",
      event_params: {
        event_name: "open_my_task",
        task_name: task.content.title,
        task_id: task._id,
        task_domain: task.domain,
        task_goal_calmerNights: task.goal.includes("calmerNights"),
        task_goal_health: task.goal.includes("health"),
        task_goal_energy: task.goal.includes("energy"),
      },
    });
    setModalShowing(true);
    task.tags = [
      getTranslation(task.domain).toUpperCase(),
      getTranslation(task.subType).toUpperCase(),
    ];
    setOpenedTask(task);
  };

  const closeModal = (task, type) => {
    setClosedTask(task);
    setModalShowing(false);
    // setOpenedTask(null);
  };

  return (
    <div>
      <div>
        {state.user.profile && (
          <div className="home">
            <div className="home__text" ref={containerRef}>
              {props.overviewType === "myActions" && <Klaasje />}
              <ActionModal
                opened={modalShowing}
                closeModal={closeModal}
                modalStyle={openedTask?.domain}
                title={openedTask?.content.title}
                tags={openedTask?.tags}
                body={openedTask?.content.body}
                task={openedTask}
                overviewType={props.overviewType}
                titleTags={
                  openedTask?.interval
                    ? [
                        `${openedTask?.iterationCount || 0}/${
                          openedTask?.duration
                        } ${getDutchPlural(
                          openedTask?.interval
                        ).toUpperCase()}`,
                      ]
                    : []
                }
              />
              {inActiveTasks === 0 && props.overviewType === "allActions" && (
                <Klaasje />
              )}
              <ContentBlock title={mainText.title} copy={mainText.copy} />
              {props.overviewType === "audioActions" && (
                <Toggle
                  options={["Slaapgeluiden", "Meditatie"]}
                  onChange={toggleSwitch}
                />
              )}
              {audioSubType === "sleepSounds" &&
                props.overviewType === "audioActions" && (
                  <ContentBlock
                    isSpecial
                    title=""
                    copy={`Deze geluiden kunnen je helpen bij het in slaap vallen. Probeer de
                    verschillende opties en ontdek welk geluid jij het prettigst vindt.`}
                  />
                )}
              {audioSubType === "meditation" &&
                props.overviewType === "audioActions" && (
                  <ContentBlock
                    isSpecial
                    title=""
                    copy={`Klaasje helpt je tot rust te komen aan de hand van verschillende meditatie oefeningen. Kies de lengte die je prettig vindt of waar je eenvoudig nu tijd voor hebt.`}
                  />
                )}
              {taskOverview.length === domains.length &&
                populated &&
                generateDomains(taskOverview)}
              {taskOverview.length === sleepSounds.length &&
                props.overviewType === "audioActions" &&
                generateDomains(taskOverview)}
              {taskOverview.length === meditationSounds.length &&
                props.overviewType === "audioActions" &&
                generateDomains(taskOverview)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskOverview;
