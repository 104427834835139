import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Setting.scss";
import Toggle from "react-toggle";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Button from "../Button/Button";

function Setting(props) {
  const [value, setValue] = useState(props.value, false);

  const availableSettingTypes = [
    "input",
    "select",
    "boolean",
    "link",
    "button",
  ];

  if (!props.name || props.name === "") {
    console.warn("Setting should have a name property");
    return <></>;
  }

  if (!availableSettingTypes.includes(props.type)) {
    console.warn("Setting type does not exist");
    return <></>;
  }

  var selectOptions = [];

  if (props.type === "select") {
    selectOptions = props.options?.map((option, index) => {
      return (
        <option value={option.value} key={"setting_option_" + index}>
          {option.label}
        </option>
      );
    });
  }

  if (props.type === "link") {
    if (!props.to) {
      console.warn('Setting with type of "Link" should have a "to" property');
      return <></>;
    }

    return (
      <NavLink className={"Setting Setting--type--" + props.type} to={props.to}>
        <div className="Setting__content">
          <div className="Setting__label">{props.label}</div>
          <FontAwesomeIcon className="Setting__icon" icon={faChevronRight} />
        </div>
      </NavLink>
    );
  }

  if (props.type === "button") {
    return (
      <Button
        copy={props.label}
        onClick={props.onClick}
        buttonStyle={props.buttonStyle || "orange"}
      ></Button>
      // <div
      //   className={"Setting Setting--type--" + props.type}
      //   onClick={props.onClick}
      // >
      //   <div className="Setting__content">
      //     <div className="Setting__label">{props.label}</div>
      //   </div>
      // </div>
    );
  }

  return (
    <div
      className={
        "Setting Setting--type--" +
        props.type +
        (props.disabled ? " Setting--disabled" : "")
      }
    >
      <div className="Setting__content">
        <label className="Setting__label" htmlFor={"setting_" + props.name}>
          {props.label}
        </label>
        {props.type === "input" && (
          <div className="Setting__input">
            <input
              className={
                "Setting__input__element" +
                (props.icon ? " Setting__input__element--hasIcon" : "")
              }
              type="text"
              placeholder={props.placeholder}
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
                props.onChange(event.target.value);
              }}
            />
            {props.icon && !props.disabled && (
              <FontAwesomeIcon
                className="Setting__input__icon"
                icon={props.icon}
              />
            )}
          </div>
        )}
        {props.type === "boolean" && (
          <div className="Setting__boolean">
            <Toggle
              icons={false}
              onChange={(event) => {
                setValue(!value);
                if (typeof props.onChange === "function")
                  props.onChange(!value);
              }}
              checked={value}
            />
          </div>
        )}
        {props.type === "select" && (
          <div className="Setting__select">
            <select
              className={"Setting__select__element"}
              type="select"
              name={"setting_" + props.name}
              placeholder={props.placeholder}
              defaultValue={value}
              onChange={(event) => {
                setValue(event.target.value);
                if (typeof props.onChange === "function")
                  props.onChange(event.target.value);
              }}
            >
              {selectOptions}
            </select>
            <FontAwesomeIcon
              className="Setting__select__icon"
              icon={faChevronDown}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Setting;
