import { localStorage } from "../context/localStorage/localStorage"

let globalLimits = {
  maxCalls: 10,
  maxDuration: 5,
}

function _isLater(a, b) {
  return a > b;
}

const maxCallsExeededError = new Error('Rate limit exeeded');

async function isRateLimited() {
  let limits = localStorage.limiter.get();

  const currentTime = new Date().getTime();

  const offsetTime = globalLimits.maxDuration * 1000;

  if (!limits || !limits?.global?.timestamp || _isLater(currentTime - offsetTime, limits.global.timestamp)) {
    // console.log('first time');
    if (!limits.global) limits.global = {};
    limits.global.timestamp = currentTime;
    limits.global.calls = 1;
    localStorage.limiter.set(limits);
    return ;
  } else {
    limits.global.calls += 1;
    localStorage.limiter.set(limits);
    if (limits.global.calls > globalLimits.maxCalls) {
      throw maxCallsExeededError;
    }
    return false;
  }
}

export {
  isRateLimited,
}
