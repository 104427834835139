import { localStorage } from "../context/localStorage/localStorage";

const themeCheckIntervalTime = 30000; // 30 seconds
const darkModeHours = [18, 8]; // [startTime, endTime]

/** @type {(theme: "light"|"dark") => {}} */
const sendChange = (theme = "light") => {
  if (typeof themeChecker.onChange === "function") themeChecker.onChange(theme);
};

// Check for daytime saving (DST)
function isDST(d) {
  let jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
  let jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== d.getTimezoneOffset();
}

const onCheck = () => {
  const checkType = localStorage.autoTheme.get();

  if (checkType === "timeOfDay") {
    var currentTime = new Date();

    var startHours = isDST(currentTime)
      ? darkModeHours[0]
      : darkModeHours[0] + 1; // Set the start time one hour later if note daytimesaving
    var endHours = isDST(currentTime) ? darkModeHours[1] : darkModeHours[1] - 1; // Set the end time one hour later if daytimesaving

    if (
      currentTime.getHours() >= startHours ||
      currentTime.getHours() < endHours
    ) {
      sendChange("dark");
    } else {
      sendChange("light");
    }
  }
};

const onChanged = (event) => {
  const newColorScheme = event.matches ? "dark" : "light";
  sendChange(newColorScheme);
};

const startThemeChecker = () => {
  if (themeChecker.isActive) return;
  themeChecker.isActive = true;

  let checkType = localStorage.autoTheme.get();

  if (!checkType) {
    checkType = "timeOfDay";
    localStorage.autoTheme.set("timeOfDay");
  }

  if (checkType === "os") {
    // Setup a watcher to check for OS dark mode changes
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", onChanged);

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      // Dark mode active
      sendChange("dark");
    } else {
      // Light mode active
      sendChange("light");
    }
  } else if (checkType === "timeOfDay") {
    themeChecker.interval = setInterval(onCheck, themeCheckIntervalTime);
    onCheck();
  }
};

const stopThemeChecker = () => {
  clearInterval(themeChecker.interval);
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .removeEventListener("change", onChanged);

  themeChecker.isActive = false;
};

const onTypeChanged = () => {
  stopThemeChecker();
  startThemeChecker();
};

export const themeChecker = {
  start: startThemeChecker,
  stop: stopThemeChecker,
  onTypeChanged,
  interval: false,
  isActive: false,
  onChange: false,
};
