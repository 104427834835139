import KlaasjeAction from "../../Components/KlaasjeAction/KlaasjeAction";
import klaasje404 from "../../assets/images/klaasje-404.svg";
import { useEffect, useState } from "react";
import './Offline.scss';
import LogoSubline from "../../Components/LogoSubline/LogoSubline";

export function Offline(props) {

  const [isOnline, setOnlineState] = useState(navigator.onLine);

  const setOnline = () => {setOnlineState(true)};
  const setOffline = () => {setOnlineState(false)};

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    }
  }, [])

  if (isOnline) return (props.children);

  return (
    <div className="Offline">
      <KlaasjeAction klaasjeImage={klaasje404}></KlaasjeAction>
      <div className="Offline__content">
        <h2>Geen verbinding</h2>
        <p>Je hebt op dit moment geen internet verbinding. Maak verbinding om de app weer te kunnen gebruiken.</p>
      </div>
      <div className="Offline__logo__container">
        <LogoSubline colorMode="light"></LogoSubline>
      </div>
      <div className="Offline__background__decal"></div>
    </div>
  );
}
