import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useLayoutEffect } from "react";
import "./Input.scss";

function Input(props) {
  const containerRef = React.createRef();
  const selectRef = React.createRef();
  const saveButtonRef = React.createRef();

  const [selectedTime, setSelectedTime] = React.useState(false);

  useLayoutEffect(() => {
    calculateInputHeight();
  });

  function onResize() {
    if (containerRef.current?.clientHeight)  {
      document.querySelector(':root').style.setProperty('--intakeInputCurrentHeight', `${containerRef.current.clientHeight}px`);
    } else {
      document.querySelector(':root').style.setProperty('--intakeInputCurrentHeight', `0px`);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  function calculateInputHeight() {
    onResize();
    // const currentContainer = containerRef.current;
    // if (!currentContainer) return;
    // const height = currentContainer.getBoundingClientRect().height;
    // if (height === undefined || height === "") return;
    // props.api.setFlowSize(parseInt(height, 10));
  }

  function handleItemClick(answer) {
    const answerToSave = {
      event: props.answers.event,
      content: [answer.content],
    };

    props.api.onAnswerGiven(answerToSave);
  }

  function handleSelectClick(event) {
    if (!isOptionSelected()) return;
    const selectedValues = [];
    const selectedText = [];
    Array.from(selectRef.current.children).forEach((element) => {
      if (element.classList.contains("selected")) {
        selectedValues.push(element.dataset.value);
        selectedText.push(element.textContent);
      }
    });

    const answer = {
      event: props.answers.event,
      value: selectedValues,
      content: selectedText,
    };

    props.api.onAnswerGiven(answer);
  }

  function changeSelectState(event) {
    event.target.classList.toggle("selected");
    isOptionSelected()
      ? saveButtonRef.current.classList.remove("disabled")
      : saveButtonRef.current.classList.add("disabled");
  }

  function isOptionSelected() {
    let isSelected = false;
    Array.from(selectRef.current.children).forEach((element) => {
      if (element.classList.contains("selected")) isSelected = true;
    });
    return isSelected;
  }

  /**
   * JSX related to rendering one or more buttons that will continue directly on click
   */
  function renderButtons(content) {
    const buttons = [];
    content.options.forEach((element, index) => {
      buttons.push(
        <div
          className="InputField__input InputField__input__button"
          key={"inputElement" + index}
          onClick={handleItemClick.bind(this, element)}
        >
          {element.content}
        </div>
      );
    });
    return buttons;
  }

  /**
   * JSX related to rendering a multiple-choice answer panel
   */
  function renderMulti(content) {
    const buttons = [];
    content.options.forEach((element, index) => {
      buttons.push(
        <div
          className="InputField__input InputField__input__select"
          key={"inputElement" + index}
          onClick={changeSelectState}
          data-value={element.saveValue}
        >
          {element.content}
        </div>
      );
    });
    return (
      <>
        <div className="InputField__list" ref={selectRef}>
          {buttons}
        </div>
        <div
          className="InputField__input InputField__input__button save disabled"
          ref={saveButtonRef}
          onClick={handleSelectClick}
        >
          <span>Volgende</span>
          <FontAwesomeIcon icon={faArrowRight}/>
        </div>
      </>
    );
  }

  /**
   * JSX related to rendering a single-choice panel
   */
  function renderSingle(content) {
    const optionListRef = React.createRef();
    const saveRef = React.createRef();

    // Handle saving of the selected value
    function onSave() {
      const selectedElement = Array.from(optionListRef.current.children).find(
        (element) => element.classList.contains("selected")
      );
      if (!selectedElement) return;
      const value = selectedElement.dataset.value;
      const textValue = selectedElement.textContent;

      const answer = {
        event: props.answers.event,
        value,
        content: [textValue],
      };

      selectedElement.classList.remove("selected");

      props.api.onAnswerGiven(answer);
    }

    // Check whether one of the options is selected
    function isOptionSelected() {
      let isSelected = false;
      Array.from(optionListRef.current.children).forEach((element) => {
        if (element.classList.contains("selected")) isSelected = true;
      });
      return isSelected;
    }

    // Change the selected option
    function changeSelectState(event) {
      Array.from(optionListRef.current.children).forEach((element) => {
        element.classList.remove("selected");
      });
      event.target.classList.add("selected");

      isOptionSelected()
        ? saveRef.current.classList.remove("disabled")
        : saveRef.current.classList.add("disabled");
    }

    // Create the options
    const buttons = [];
    content.options.forEach((element, index) => {
      buttons.push(
        <div
          className="InputField__input InputField__input__select"
          key={"inputElement" + index}
          onClick={changeSelectState}
          data-value={element.saveValue}
        >
          {element.content}
        </div>
      );
    });

    return (
      <>
        <div className="InputField__list" ref={optionListRef}>
          {buttons}
        </div>
        <div
          className="InputField__input InputField__input__button save disabled"
          ref={saveRef}
          onClick={onSave}
        >
          <span>Volgende</span>
          <FontAwesomeIcon icon={faArrowRight}/>
        </div>
      </>
    );
  }

  /**
   * JSX related to rendering a time answer panel
   */
  function renderTime(content) {
    const defaultTime = content?.content?.defaultTime || "12:00";
    if (!selectedTime) setSelectedTime(defaultTime);

    function onValueChanged(e) {
      const value = (typeof e === 'string') ? e : e.target.value;

      setSelectedTime(value || false);
    }

    if (!window.mline) window.mline = {};
    window.mline.intake = {
      setTime: (val) => {
        onValueChanged(val);
      }
    };

    function onhandleTimeClick() {
      if (!selectedTime) return;

      const answer = {
        event: props.answers.event,
        value: selectedTime,
        content: [selectedTime],
      };

      props.api.onAnswerGiven(answer);
      setSelectedTime(false);
    }

    return (
      <>
        <div className="InputField__container__time">
          <label htmlFor="timeInput" className="InputField__input__time__label">
            {selectedTime || defaultTime}
          </label>
          <input
            id="timeInput"
            type="time"
            className="InputField__input InputField__input__time"
            onChange={onValueChanged}
          ></input>
        </div>
        <div
          className={
            "InputField__input InputField__input__button save" +
            (!selectedTime ? "disabled" : "")
          }
          ref={saveButtonRef}
          onClick={onhandleTimeClick}
        >
          <span>Volgende</span>
          <FontAwesomeIcon icon={faArrowRight}/>
        </div>
      </>
    );
  }

  return (
    <div className={"InputField --" + (props.type)} ref={containerRef}>
      {props.type === "button" && renderButtons(props.answers)}
      {props.type === "multi" && renderMulti(props.answers)}
      {props.type === "single" && renderSingle(props.answers)}
      {props.type === "time" && renderTime(props.answers)}
    </div>
  );
}

export default Input;
