import Logo from "../Logo/Logo";
import "./Header.scss";
import "react-toggle/style.css";
import React, { useLayoutEffect, useRef } from "react";
import { useGlobalContext } from "../../context/store";
import { Link } from "react-router-dom";
import { isDev } from "../../helpers/devDetection";

const Header = (props) => {
  const [state] = useGlobalContext();

  const headerRef = useRef();

  useLayoutEffect(() => {
    document.querySelector(':root').style.setProperty('--headerCurrentHeight', `${headerRef.current.clientHeight}px`);
  });

  function onResize() {
    document.querySelector(':root').style.setProperty('--headerCurrentHeight', `${headerRef.current.clientHeight}px`);
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  return (
    <div className="Header underlined" ref={headerRef}>
      <Link to="/home">
        <div className="Header__logo">
          <Logo colorMode={state.theme.theme}></Logo>
        </div>
      </Link>
      {isDev() &&
        <div className="developmentFlag">DEVBUILD</div>
      }
    </div>
  );
};

export default Header;
