
import "./KlaasjeAction.scss";


function KlaasjeAction(props) {
  return (
    <div className={"actionKlaasje" + ((props.category) ? ` --${props.category}` : '')}>
      <div className="actionKlaasje__imageContainer">
        <img src={props.klaasjeImage} alt='klaasjeImage' />
      </div>
    </div >
  )
}

export default KlaasjeAction
