import "./KlaasjeMobile.scss";

function KlaasjeMobile() {
  return (
    <div className="klaasjeMobile">
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 803.85 775.64"
      >
        <defs>
          <clipPath id="clippath">
            <ellipse
              class="cls-1"
              fill="none"
              cx="404.43"
              cy="188.5"
              rx="264.5"
              ry="188.5"
            />
          </clipPath>
          <clipPath id="clippath-1">
            <path
              class="cls-1"
              fill="none"
              d="M183.12 26h495.22v749.64H183.12z"
            />
          </clipPath>
          <clipPath id="clippath-2">
            <path
              class="cls-1"
              fill="none"
              d="M209.31 162.21c130.18 11.6 375.78 83.2 485.66 160.89 109.88 77.69 138.62 195.61 76.99 320.98s-294.36 109.97-396.81 87.7S8.39 691.37.5 520.58s78.62-369.97 208.81-358.37Z"
            />
          </clipPath>
          <clipPath id="clippath-3">
            <path
              class="cls-1"
              fill="none"
              d="M183.12 26h495.22v749.64H183.12z"
            />
          </clipPath>
        </defs>
        <path
          id="Path_11-2"
          d="M209.31 162.21c130.18 11.6 375.78 83.2 485.66 160.89 109.88 77.69 138.62 195.61 76.99 320.98s-294.36 109.97-396.81 87.7S8.39 691.37.5 520.58s78.62-369.97 208.81-358.37Z"
          fill="#fff"
        />
        <g clip-path="url(#clippath)">
          <g clip-path="url(#clippath-1)">
            <path
              id="Path_1"
              class="cls-3"
              fill="#f49b79"
              d="m562.03 600.28-12.21 14.74c20.41-26.16 51.84-53.74 79.08-72.69l42.29-13.68a429.571 429.571 0 0 1-109.78 169.88c-6.1 5.78-12.59 11.51-20.47 14.41-17.25 6.32-43.23-14.49-48.17-32.18l-18.96-2.94 17.55-121.67 68.25 2.73c.7 11.13 1.75 30.27 2.45 41.41"
            />
            <path
              id="Path_2"
              d="M199.45 393.24c2.16-13.74 4.27-27.37 9.22-40.37s10.11-26.33 9.97-40.24c-.23-23.26-15.13-46.87-6.58-68.5 3.87-9.81 12.27-17.77 14.04-28.17 1.4-8.24-1.63-16.49-4.16-24.44-6.78-21.28-10.3-44.01-6.66-66.04s15.01-43.35 33.43-55.99c18.41-12.64 44.15-15.24 63.31-3.78 24.7-27.48 62.64-42.47 99.44-39.3s71.63 24.45 91.24 55.76c9.49 15.15 15.61 33.51 11.68 50.95-2.17 9.62-7.25 18.39-9.55 27.98-5.58 23.2 6.38 47.98 24.62 63.35 18.19 15.32 41.92 23.08 59.58 39.02 21.24 19.17 31.4 49.83 25.83 77.89s-26.68 52.5-53.63 62.1l-308.59 67.76c-54.62 61.21-61.07-27.92-53.2-77.99"
            />
            <path
              id="Path_3"
              class="cls-3"
              fill="#f49b79"
              d="M324.43 344.09c34.36-26.39 22.53-69.77 8.42-112.36l102.05 58.76c5.41 31.1 6.22 51.22 15.26 67.53 35.48 88.65-1.7 142.04-12.24 150.6S367 487.6 363.1 476.76c-5-13.9-23.75-89.98-32.64-101.77s-10.28-16.75-6.03-30.89"
            />
            <path
              id="Path_4"
              class="cls-3"
              fill="#f49b79"
              d="m302.54 220.2 27.18 23.99c21.8 24.25 42.63 41.67 74.14 50 8.82 2.39 18.4 3.96 27.02.9 11.23-3.92 18.5-14.76 23.76-25.37 18.88-38.04 22.87-83.2 11.33-124.03a79.79 79.79 0 0 1-39.38 4.27 80.458 80.458 0 0 1-48-25.93c6.16 13.67 6.51 32.82.58 46.03a50.854 50.854 0 0 1-36.13 28.63c-8.47 1.63-17.54 1.05-25.5 4.43-7.25 3.12-12.6 9.66-15 17.09"
            />
            <path
              id="Path_5"
              class="cls-3"
              fill="#f49b79"
              d="M335.65 208.37a18.064 18.064 0 0 0-11.46-14.23 18.023 18.023 0 0 0-17.92 3.52c-5.16 4.69-7.12 12.14-6.56 19.09.87 9.93 6.19 18.93 14.46 24.49 8.19 5.41 17.98 9.5 27.29 6.4l-5.81-39.27Z"
            />
            <path
              id="Path_6"
              d="M578.17 559.7c-7.71-79.67-28.37-138.69-52.62-162.94-17.87-17.86-51.2-31.33-75.4-38.75-4.05 51.36-23.34 109.18-23.34 109.18-2.57 5.65-7.19 10.62-13.12 12.21-10.05 2.68-22.89-2.4-27.28-11.81-16.38-34.93-61.98-123.5-61.98-123.5-56.5 10.27-92.97 33.04-114.94 53.41-6.67 6.22-13.07 13.07-17.12 21.29-5.82 11.76-6.4 25.34-6.85 38.41-1.43 42.29-.98 18.72-2.4 61.01 5.94 3.76 11.87 7.48 17.81 11.01 22.31 13.3 50.11 24.18 74.82 23.5l-44.7 45.56c.8 11.64 1.88 24.02 2.69 36.7 1.88 29.91-4.94 79.9-11.33 117.52 120.21 30.94 204.12 30.46 282.77.88-10.39-60.33-19.64-130.96-9.6-191.23 32.13 7.53 54.27 6.68 82.58-2.45"
            />
            <path
              id="Path_7"
              class="cls-3"
              fill="#f49b79"
              d="M284.2 550.42c9.58 28.88 21.99 50.68 32.71 62.38l-6.93 4.27c39.21-28.37 91.2-37.9 125.79-71.85 19.4-19.07 31.85-44.46 51.88-62.9 7.99-7.36 17.69-13.7 28.59-14.38 13.53-.86 25.85 7.13 37.1 14.78l.52.97c-2 10.57-12.2 17.51-22.77 15.51-3.18-.6-6.17-1.99-8.68-4.04-5.31 8.39-4.16 19.12-4.23 29.05-.05 9.99-2.62 21.51-11.64 25.74-6.28 3.03-13.64 1.43-20.6 1.94-9.88.74-18.95 6-26.31 12.67-7.31 6.62-13.13 14.72-19.12 22.6-39.33 51.25-87.72 85.32-144.45 116.26 0 0-4.4-2.51-10.73-7.07a147.662 147.662 0 0 1-22.2-19.74 214.212 214.212 0 0 1-29.4-41.61c-16.33-29.57-27.91-65.29-32.83-105.76 22.19 11.62 34.34 20.49 73.97 22.07 2.19.08 9.31-.88 9.31-.88"
            />
            <path
              id="Path_8"
              d="M607.08 552.36c-21.61-1.71-43.22-3.42-64.83-5.14l-2.28.69c14.25-31.78 28.49-63.56 42.74-95.34 3.42-7.63 10.04-22.95 10.04-22.95 10.62-.16 17.77-.01 24.59.17 20.34.57 40.68 1.13 61.01 1.7-6.78 14.69-13.57 29.38-20.35 44.08"
              fill="#909090"
            />
            <path
              id="Path_9"
              class="cls-3"
              fill="#f49b79"
              d="M671.18 528.64c2.34-3.23 2.32-6.38 1.12-10.18-4.09-12.97-8.18-25.95-12.27-38.92l-44.6-11.79 1.11.41c-3.68-1.25-7.79 1.24-9.7 4.63s-2.15 7.43-2.35 11.31l-1.14 22.08c-.32 6.2-.63 12.57 1.29 18.47a26.278 26.278 0 0 0 24.26 17.69"
            />
          </g>
        </g>
        <g clip-path="url(#clippath-2)" id="Klaasje_homepage-2">
          <g clip-path="url(#clippath-3)" id="Mask_Group_3">
            <g id="Klaasje_1-2">
              <path
                id="Path_1-2"
                class="cls-3"
                fill="#f49b79"
                d="m562.03 600.28-12.21 14.74c20.41-26.16 51.84-53.74 79.08-72.69l42.29-13.68a429.571 429.571 0 0 1-109.78 169.88c-6.1 5.78-12.59 11.51-20.47 14.41-17.25 6.32-43.23-14.49-48.17-32.18l-18.96-2.94 17.55-121.67 68.25 2.73c.7 11.13 1.75 30.27 2.45 41.41"
              />
              <path
                id="Path_2-2"
                d="M199.45 393.24c2.16-13.74 4.27-27.37 9.22-40.37s10.11-26.33 9.97-40.24c-.23-23.26-15.13-46.87-6.58-68.5 3.87-9.81 12.27-17.77 14.04-28.17 1.4-8.24-1.63-16.49-4.16-24.44-6.78-21.28-10.3-44.01-6.66-66.04s15.01-43.35 33.43-55.99c18.41-12.64 44.15-15.24 63.31-3.78 24.7-27.48 62.64-42.47 99.44-39.3s71.63 24.45 91.24 55.76c9.49 15.15 15.61 33.51 11.68 50.95-2.17 9.62-7.25 18.39-9.55 27.98-5.58 23.2 6.38 47.98 24.62 63.35 18.19 15.32 41.92 23.08 59.58 39.02 21.24 19.17 31.4 49.83 25.83 77.89s-26.68 52.5-53.63 62.1l-308.59 67.76c-54.62 61.21-61.07-27.92-53.2-77.99"
              />
              <path
                id="Path_3-2"
                class="cls-3"
                fill="#f49b79"
                d="M324.43 344.09c34.36-26.39 22.53-69.77 8.42-112.36l102.05 58.76c5.41 31.1 6.22 51.22 15.26 67.53 35.48 88.65-1.7 142.04-12.24 150.6S367 487.6 363.1 476.76c-5-13.9-23.75-89.98-32.64-101.77s-10.28-16.75-6.03-30.89"
              />
              <path
                id="Path_4-2"
                class="cls-3"
                fill="#f49b79"
                d="m302.54 220.2 27.18 23.99c21.8 24.25 42.63 41.67 74.14 50 8.82 2.39 18.4 3.96 27.02.9 11.23-3.92 18.5-14.76 23.76-25.37 18.88-38.04 22.87-83.2 11.33-124.03a79.79 79.79 0 0 1-39.38 4.27 80.458 80.458 0 0 1-48-25.93c6.16 13.67 6.51 32.82.58 46.03a50.854 50.854 0 0 1-36.13 28.63c-8.47 1.63-17.54 1.05-25.5 4.43-7.25 3.12-12.6 9.66-15 17.09"
              />
              <path
                id="Path_5-2"
                class="cls-3"
                fill="#f49b79"
                d="M335.65 208.37a18.064 18.064 0 0 0-11.46-14.23 18.023 18.023 0 0 0-17.92 3.52c-5.16 4.69-7.12 12.14-6.56 19.09.87 9.93 6.19 18.93 14.46 24.49 8.19 5.41 17.98 9.5 27.29 6.4l-5.81-39.27Z"
              />
              <path
                id="Path_6-2"
                d="M578.17 559.7c-7.71-79.67-28.37-138.69-52.62-162.94-17.87-17.86-51.2-31.33-75.4-38.75-4.05 51.36-23.34 109.18-23.34 109.18-2.57 5.65-7.19 10.62-13.12 12.21-10.05 2.68-22.89-2.4-27.28-11.81-16.38-34.93-61.98-123.5-61.98-123.5-56.5 10.27-92.97 33.04-114.94 53.41-6.67 6.22-13.07 13.07-17.12 21.29-5.82 11.76-6.4 25.34-6.85 38.41-1.43 42.29-.98 18.72-2.4 61.01 5.94 3.76 11.87 7.48 17.81 11.01 22.31 13.3 50.11 24.18 74.82 23.5l-44.7 45.56c.8 11.64 1.88 24.02 2.69 36.7 1.88 29.91-4.94 79.9-11.33 117.52 120.21 30.94 204.12 30.46 282.77.88-10.39-60.33-19.64-130.96-9.6-191.23 32.13 7.53 54.27 6.68 82.58-2.45"
              />
              <path
                id="Path_7-2"
                class="cls-3"
                fill="#f49b79"
                d="M284.2 550.42c9.58 28.88 21.99 50.68 32.71 62.38l-6.93 4.27c39.21-28.37 91.2-37.9 125.79-71.85 19.4-19.07 31.85-44.46 51.88-62.9 7.99-7.36 17.69-13.7 28.59-14.38 13.53-.86 25.85 7.13 37.1 14.78l.52.97c-2 10.57-12.2 17.51-22.77 15.51-3.18-.6-6.17-1.99-8.68-4.04-5.31 8.39-4.16 19.12-4.23 29.05-.05 9.99-2.62 21.51-11.64 25.74-6.28 3.03-13.64 1.43-20.6 1.94-9.88.74-18.95 6-26.31 12.67-7.31 6.62-13.13 14.72-19.12 22.6-39.33 51.25-87.72 85.32-144.45 116.26 0 0-4.4-2.51-10.73-7.07a147.662 147.662 0 0 1-22.2-19.74 214.212 214.212 0 0 1-29.4-41.61c-16.33-29.57-27.91-65.29-32.83-105.76 22.19 11.62 34.34 20.49 73.97 22.07 2.19.08 9.31-.88 9.31-.88"
              />
              <path
                id="Path_8-2"
                d="M607.08 552.36c-21.61-1.71-43.22-3.42-64.83-5.14l-2.28.69c14.25-31.78 28.49-63.56 42.74-95.34 3.42-7.63 10.04-22.95 10.04-22.95 10.62-.16 17.77-.01 24.59.17 20.34.57 40.68 1.13 61.01 1.7-6.78 14.69-13.57 29.38-20.35 44.08"
              />
              <path
                id="Path_9-2"
                class="cls-3"
                fill="#f49b79"
                d="M671.18 528.64c2.34-3.23 2.32-6.38 1.12-10.18-4.09-12.97-8.18-25.95-12.27-38.92l-44.6-11.79 1.11.41c-3.68-1.25-7.79 1.24-9.7 4.63s-2.15 7.43-2.35 11.31l-1.14 22.08c-.32 6.2-.63 12.57 1.29 18.47a26.278 26.278 0 0 0 24.26 17.69"
              />
            </g>
          </g>
        </g>
        <path
          class="cls-3"
          fill="#f49b79"
          d="M454.96 270.01c-5.26 10.62-12.53 21.45-23.76 25.37-8.62 3.07-18.19 1.49-27.02-.9-31.51-8.33-52.34-25.75-74.14-50l-27.18-23.98c2.4-7.43 7.75-13.97 15-17.09 7.97-3.38 17.03-2.8 25.5-4.43a50.863 50.863 0 0 0 36.14-28.62c5.92-13.22 5.57-32.37-.58-46.04a80.43 80.43 0 0 0 48 25.93c13.27 1.91 26.82.44 39.38-4.27 11.53 40.83 7.54 85.99-11.34 124.03Z"
        />
      </svg>
    </div>
  );
}

export default KlaasjeMobile;
