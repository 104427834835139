import "./ContentBlock.scss";

function ContentBlock(props) {
  return (
    <div
      className={
        "contentBlock" +
        (props.isSpecial ? " --special" : "") +
        (props.noMargin ? " --noMargin" : "")
      }
    >
      {props.type === "action" && props.task.duration && (
        <div className="contentBlock__tagContainer">
          <div
            className={`contentBlock__tagContainer__tag contentBlock__tagContainer__tag--${props.task.domain}`}
          >
            {/* FIX DAGEN TO VARIABLE */}
            {props.task.iterationCount || 0}/{props.task.duration} dagen
          </div>
        </div>
      )}
      {props.title && <div className="contentBlock__title">{props.title}</div>}
      {props.copy && (
        <div
          className="contentBlock__copy"
          dangerouslySetInnerHTML={{ __html: props.copy }}
        ></div>
      )}
    </div>
  );
}

export default ContentBlock;
