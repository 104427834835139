import KlaasjeAction from "../KlaasjeAction/KlaasjeAction";
import "./ActionModal.scss";
import KlaasjeEatDrink from "../../assets/images/klaasje-waterbottle.svg";
import KlaasjeAlarm from "../../assets/images/klaasje-alarm.svg";
import KlaasjeSleep from "../../assets/images/klaasje-sleep.svg";
import KlaasjeYoga from "../../assets/images/klaasje-yoga.svg";

import { useRef, useEffect, useLayoutEffect } from "react";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import gsap, { Power2 } from "gsap/all";
import { Events } from "../../Logic/Events";
import { useGlobalContext } from "../../context/store";
import toast from "react-hot-toast";

let modalButtons = [];

const nextDayOrLater = (first, second) => {
  if (
    (first.getFullYear() <= second.getFullYear() &&
      first.getMonth() <= second.getMonth() &&
      first.getDate() < second.getDate()) ||
    first.getFullYear() < second.getFullYear() ||
    (first.getFullYear() <= second.getFullYear() &&
      first.getMonth() < second.getMonth())
  ) {
    return true;
  } else {
    return false;
  }
};

const getIntervalInfo = (interval) => {
  switch (interval) {
    case "daily":
      return { copy: "dag", timeChecker: nextDayOrLater };
    case "weekly":
      return { copy: "week", timeChecker: nextDayOrLater };
    case "monthly":
      return { copy: "maand", timeChecker: nextDayOrLater };
    default:
      return {};
  }
};

function ActionModal(props) {
  const [dispatch] = useGlobalContext();
  let actionModal = useRef();
  let actionModelBackground = useRef();

  let tags = [];
  let titleTags = [];
  let klaasjeAction;
  const modalStyle = props.modalStyle;

  if (modalStyle === "food") {
    klaasjeAction = KlaasjeEatDrink;
  } else if (modalStyle === "mental") {
    klaasjeAction = KlaasjeYoga;
  } else if (modalStyle === "bedroom") {
    klaasjeAction = KlaasjeAlarm;
  } else {
    //default klaasje image
    klaasjeAction = KlaasjeSleep;
  }

  const getCurrentTime = () => {
    const currentDate = new Date();
    return currentDate;
  };

  const addTaskForUser = (task) => {
    Events.set(
      props.task._id,
      {
        timeLastEdit: getCurrentTime(),
        userAdded: true,
        isActive: true,
      },
      dispatch
    );
    window.dataLayer.push({ event_params: null });
    window.dataLayer.push({
      event: "interaction",
      event_params: {
        event_name: "start_task",
        task_name: props.task.content.title,
        task_id: props.task._id,
        task_domain: props.task.domain,
        task_goal_calmerNights: props.task.goal.includes("calmerNights"),
        task_goal_health: props.task.goal.includes("health"),
        task_goal_energy: props.task.goal.includes("energy"),
      },
    });
    toast.success("Actie toegevoegd!");
    closeModal(task);
  };

  const completeTask = (type, task) => {
    switch (type) {
      case "completeIteration":
        props.closeModal(task);
        task.iterationCount = task.iterationCount | 0;
        Events.set(
          props.task._id,
          {
            timeLastEdit: getCurrentTime(),
            iterationCount: (task.iterationCount += 1),
            isActive: true,
          },
          dispatch
        );
        window.dataLayer.push({ event_params: null });
        window.dataLayer.push({
          event: "interaction",
          event_params: {
            event_name: "complete_subtask_modal",
            task_name: props.task.content.title,
            task_id: props.task._id,
            task_domain: props.task.domain,
            task_goal_calmerNights: props.task.goal.includes("calmerNights"),
            task_goal_health: props.task.goal.includes("health"),
            task_goal_energy: props.task.goal.includes("energy"),
            day: `${props.task.iterationCount} of ${props.task.duration} days`,
          },
        });
        toast.success("Vandaag voltooid!");
        break;
      case "complete":
        props.closeModal(task, "complete");
        window.dataLayer.push({ event_params: null });
        window.dataLayer.push({
          event: "interaction",
          event_params: {
            event_name: "complete_task_modal",
            task_name: props.task.content.title,
            task_id: props.task._id,
            task_domain: props.task.domain,
            task_goal_calmerNights: props.task.goal.includes("calmerNights"),
            task_goal_health: props.task.goal.includes("health"),
            task_goal_energy: props.task.goal.includes("energy"),
          },
        });
        Events.set(
          props.task._id,
          {
            timeLastEdit: getCurrentTime(),
            iterationCount: (task.iterationCount += 1),
            isCompleted: true,
            isActive: false,
          },
          dispatch
        );
        toast.success("Actie voltooid!");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (props.opened) {
      gsap.to(actionModal.current, {
        duration: 0.5,
        y: 0,
        ease: Power2.easeOut,
      });
      gsap.to(actionModelBackground.current, {
        duration: 0.5,
        autoAlpha: 1,
        ease: Power2.easeOut,
      });
      document.querySelector("#root").classList.add("--no-scroll");
    } else {
      gsap.to(actionModal.current, {
        duration: 0.5,
        y: "100%",
        ease: Power2.easeIn,
      });
      gsap.to(actionModelBackground.current, {
        duration: 0.5,
        autoAlpha: 0,
        ease: Power2.easeIn,
      });
      document.querySelector("#root").classList.remove("--no-scroll");
    }

    // On component unmount. Makes sure the app can scroll when the modal is closed in an odd way
    return () => {
      document.querySelector("#root").classList.remove("--no-scroll");
    };
  }, [props.opened]);

  useLayoutEffect(() => {
    gsap.set(actionModal.current, { y: props.opened ? 0 : "100%" });
    gsap.set(actionModelBackground.current, {
      autoAlpha: props.opened ? 1 : 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = (task) => {
    props.closeModal(task);
  };

  const getModalButtons = (task) => {
    modalButtons = [];
    if (props.overviewType === "allActions") {
      switch (task.subType) {
        case "fact":
          const factButton = {
            copy: "Toevoegen",
            type: "complete",
            buttonStyle: "orange",
            clickFunction: addTaskForUser,
          };
          const closeButton = {
            copy: "Sluiten",
            type: "close",
            buttonStyle: "secondary",
            clickFunction: closeModal,
          };
          modalButtons.push(closeButton, factButton);
          break;
        case "action":
          const actionButton = {
            copy: "Beginnen",
            type: "add",
            buttonStyle: "orange",
            clickFunction: addTaskForUser,
          };
          const closeActionButton = {
            copy: "Sluiten",
            type: "close",
            buttonStyle: "secondary",
            clickFunction: closeModal,
          };
          modalButtons.push(closeActionButton, actionButton);
          break;

        default:
          break;
      }
    } else {
      switch (task.subType) {
        case "fact":
          const factButton = {
            copy: "Afronden",
            type: "complete",
            clickFunction: completeTask,
          };
          const closeButton = {
            copy: "Sluiten",
            type: "close",
            buttonStyle: "secondary--modal",
            clickFunction: closeModal,
          };
          modalButtons.push(closeButton, factButton);
          break;
        case "action":
          const actionButton = {
            copy: "Voltooien",
            type: "complete",
            clickFunction: completeTask,
          };
          const closeActionButton = {
            copy: "Sluiten",
            type: "close",
            buttonStyle: "secondary--modal",
            clickFunction: closeModal,
          };
          modalButtons.push(closeActionButton, actionButton);
          break;

        default:
          break;
      }
      modalButtons.forEach((modalButton) => {
        let lastEdit;
        if (
          task.timeLastEdit &&
          task.timeLastEdit.toDate &&
          task.iterationCount > 0
        ) {
          lastEdit = task.timeLastEdit ? task.timeLastEdit.toDate() : undefined;
        } else if (task.timeLastEdit && task.iterationCount > 0) {
          lastEdit = new Date(task.timeLastEdit);
        }

        if (
          task.subType === "action" &&
          task.interval &&
          modalButton.type === "complete"
        ) {
          task.iterationCount = task.iterationCount || 0;
          const intervalInfo = getIntervalInfo(task.interval);
          if (
            task.iterationCount > -1 &&
            task.iterationCount < task.duration - 1
          ) {
            modalButton.copy = `Voltooi ${intervalInfo.copy} ${
              task.iterationCount + 1
            } van ${task.duration}`;
            modalButton.type = "completeIteration";
          } else if (task.iterationCount === task.duration - 1) {
            modalButton.copy = `Voltooi ${intervalInfo.copy} ${
              task.iterationCount + 1
            } van ${task.duration}`;
            modalButton.type = "complete";
          }
          if (lastEdit) {
            const currentDate = new Date();
            if (!intervalInfo.timeChecker(lastEdit, currentDate)) {
              modalButton.copy = `Kom  over 1 ${intervalInfo.copy}
             terug`;
              modalButton.disabled = true;
            }
          }
        }
      });
    }

    return modalButtons;
  };

  if (props.tags && props.tags.length > 0) {
    props.tags.forEach(function (element, index) {
      tags.push(
        <div
          className={`actionModal__taskDescription__tagContainer__tag actionModal__taskDescription__tagContainer__tag--${modalStyle}`}
          key={`modal_tag_description_${index}`}
        >
          {element}
        </div>
      );
    });
  }

  if (props.titleTags && props.titleTags.length > 0) {
    props.titleTags.forEach(function (element, index) {
      titleTags.push(
        <div
          className={`actionModal__header__copyContainer__tagContainer__tag actionModal__header__copyContainer__tagContainer__tag--${modalStyle}`}
          key={`modal_tag_title_${index}`}
        >
          {element}
        </div>
      );
    });
  }

  return (
    <div className="actionModal__container">
      <div
        className="actionModal__background"
        ref={actionModelBackground}
      ></div>
      <div
        className={
          `actionModal ${modalStyle || ""}` +
          (props.opened ? " actionModal--visible" : "")
        }
        ref={actionModal}
      >
        <FontAwesomeIcon
          className="actionModal__closeButton"
          icon={faClose}
          onClick={closeModal}
        ></FontAwesomeIcon>
        {/* <div className="" onClick={closeModal}>X</div> */}
        <div
          className={`actionModal__header actionModal__header--${modalStyle}`}
        >
          <div className="actionModal__header__copyContainer">
            {titleTags.length > 0 && (
              <div className="actionModal__header__copyContainer__tagContainer">
                {titleTags}
              </div>
            )}

            <div className="actionModal__header__copyContainer__title">
              {props.title}
            </div>
          </div>
          <KlaasjeAction klaasjeImage={klaasjeAction} category={modalStyle} />
        </div>
        <div className="actionModal__mainContent">
          {tags.length > 0 && (
            <div className="actionModal__taskDescription__tagContainer">
              {tags}
            </div>
          )}
          <div className="actionModal__taskDescription">
            <div
              className="actionModal__taskDescription__copy"
              dangerouslySetInnerHTML={{ __html: props.body }}
            >
              {/* {`${props.body}`} */}
            </div>
          </div>
          <div className="actionModal__footer">
            {props.task &&
              modalButtons &&
              getModalButtons(props.task).map((button, i) => {
                return (
                  <Button
                    disabled={button.disabled}
                    copy={button.copy}
                    className={`modalButton`}
                    buttonStyle={button.buttonStyle}
                    type={button.type}
                    onClick={() => {
                      button.clickFunction(button.type, props.task);
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActionModal;
