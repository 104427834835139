const storage = window.localStorage;

const paths = {
  currentTheme: "mline.currentTheme",
  autoTheme: "mline.autoTheme",
  rateLimiter: "mline.limits",
  tos: "mline.terms.accepted",
  homeScreenModal: "mline.HomeScreenModal",
};

function getCurrentTheme() {
  if (!storage) return false;
  return storage.getItem(paths.currentTheme);
}

function setCurrentTheme(setting) {
  if (!storage) return false;
  return storage.setItem(paths.currentTheme, setting);
}

function getAutoTheme() {
  if (!storage) return "off";
  return storage.getItem(paths.autoTheme);
}

function setAutoTheme(setting) {
  if (!storage) return false;
  return storage.setItem(paths.autoTheme, setting);
}

function getHomeScreenModal() {
  if (!storage) return false;
  return storage.getItem(paths.homeScreenModal);
}

function setHomeScreenModal(setting) {
  if (!storage) return false;
  return storage.setItem(paths.homeScreenModal, setting);
}

function getLimiter() {
  if (!storage) return false;
  const limits = storage.getItem(paths.rateLimiter);
  return limits ? JSON.parse(limits) : {};
}

function setLimiter(limits) {
  if (!storage) return false;
  return storage.setItem(paths.rateLimiter, JSON.stringify(limits));
}

function getTerms() {
  if (!storage) return false;
  return storage.getItem(paths.tos) || false;
}

function setTerms(accepted) {
  if (!storage) return false;
  return storage.setItem(paths.tos, accepted);
}

export const localStorage = {
  currentTheme: {
    get: getCurrentTheme,
    set: setCurrentTheme,
  },
  autoTheme: {
    get: getAutoTheme,
    set: setAutoTheme,
  },
  limiter: {
    get: getLimiter,
    set: setLimiter,
  },
  terms: {
    get: getTerms,
    set: setTerms,
  },
  homeScreenModal: {
    get: getHomeScreenModal,
    set: setHomeScreenModal,
  },
};
