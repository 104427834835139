import KlaasjeAction from "../../Components/KlaasjeAction/KlaasjeAction";
import klaasje404 from "../../assets/images/klaasje-404.svg";
import Button from "../../Components/Button/Button";
import './404.scss';
import { useNavigate } from "react-router-dom";

function NotFound(props) {
  const navigate = useNavigate();

  return (
    <div className="ErrorView">
      <KlaasjeAction klaasjeImage={klaasje404}></KlaasjeAction>
      <div className="ErrorView__content">
        <h2>404</h2>
        <p>Whoops, het lijkt erop dat we je deze pagina niet kunnen laten zien. Probeer het nog een keer.</p>
        <Button buttonType="orange" copy="Terug naar home" onClick={() => navigate('/home')}></Button>
      </div>
    </div>
  );
}

export {
  NotFound,
}
