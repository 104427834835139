import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import KlaasjeMobile from "../KlaasjeMobile/KlaasjeMobile";
import { ReactComponent as WavesSvg } from '../../assets/images/waveLines.svg'
import "./Startup.scss";

function Startup() {
  const navigate = useNavigate();
  const [startIntake, setStartIntake] = useState(false)

  useEffect(() => {
    if (startIntake) navigate('/intake')
  })



  return (
    <div className="startup">
      <div className={`startup__waves`}>
        <WavesSvg />
      </div>
      <KlaasjeMobile />
      <div className="startup__copyContainer">
        <div className="startup__copyContainer__header">
          <span className="startup__copyContainer__header--bold">Welkom! Ik ben klaasje.</span> <br /> <span className="startup__copyContainer__header--regular">Je Sleep Performance Coach</span>
        </div>
        <div className="startup__copyContainer__cta" onClick={function () { setStartIntake(true) }} >
          Laten we beginnen
        </div>
      </div>

    </div >
  );
}
export default Startup;
