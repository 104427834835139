import { getDocs } from "firebase/firestore";

const getResults = async (query) => {
  const docsSnap = await getDocs(query);
  let results = [];
  docsSnap.forEach((doc) => {
    const docData = doc.data();
    docData._id = doc.id;
    results.push(docData);
  });
  return results;
};

export {
  getResults,
}
