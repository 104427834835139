import "./Klaasje.scss";

function Klaasje() {
  return (
    <div className="Klaasje">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 764.313 659.638"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              id="Path_10"
              data-name="Path 10"
              d="M366.268,40.383C493.844,36.119,464.656,149.305,558.2,246.392s239.233,36.87,179.31,222.167S292.39,608.32,189.94,586.052,1.178,521.7,16.74,354.885,238.693,44.647,366.268,40.383Z"
              transform="translate(156.77 261.958)"
              fill="#003148"
            />
          </clipPath>
          <clipPath id="clip-path-2">
            <rect
              id="Rectangle_3"
              data-name="Rectangle 3"
              width="414.955"
              height="100%"
              fill="none"
            />
          </clipPath>
          <clipPath id="clip-path-3">
            <ellipse
              id="Ellipse_6"
              data-name="Ellipse 6"
              cx="211.5"
              cy="205"
              rx="211.5"
              ry="205"
              transform="translate(144 210)"
              fill="#fff"
              stroke="#707070"
              strokeWidth="1"
            />
          </clipPath>
        </defs>
        <g id="tempKlaasje" transform="translate(-144 -210)">
          <path
            id="Path_11"
            data-name="Path 11"
            d="M366.268,40.383c130.184,11.6,98.388,108.922,191.934,206.009s239.233,36.87,179.31,222.167S292.39,608.32,189.94,586.052,1.178,521.7,16.74,354.885,236.084,28.781,366.268,40.383Z"
            transform="translate(156.77 261.958)"
            fill="#003148"
          />
          <g
            id="Mask_Group_1"
            data-name="Mask Group 1"
            clipPath="url(#clip-path)"
          >
            <g
              id="Klaasje_1"
              data-name="Klaasje 1"
              transform="translate(188.523 271.862)"
              clipPath="url(#clip-path-2)"
            >
              <path
                id="Path_1"
                data-name="Path 1"
                d="M118.972,137.9,108.739,150.25c17.1-21.921,43.436-45.032,66.26-60.911l35.437-11.466a359.931,359.931,0,0,1-91.989,142.342c-5.111,4.846-10.547,9.649-17.156,12.072-14.452,5.295-36.226-12.142-40.369-26.962l-15.89-2.461L59.738,100.913l57.185,2.288c.584,9.324,1.466,25.366,2.05,34.7"
                transform="translate(198.526 343.307)"
                fill="#f49b79"
              />
              <path
                id="Path_2"
                data-name="Path 2"
                d="M4.412,307.72c1.806-11.509,3.575-22.938,7.729-33.825s8.47-22.061,8.356-33.717c-.189-19.487-12.678-39.271-5.517-57.4,3.245-8.221,10.282-14.89,11.764-23.6,1.174-6.9-1.368-13.813-3.489-20.482-5.684-17.832-8.627-36.875-5.582-55.34s12.58-36.318,28.011-46.908,36.994-12.77,53.052-3.164A100.418,100.418,0,0,1,258.516,47.071c7.956,12.694,13.078,28.076,9.789,42.7-1.817,8.064-6.074,15.409-8,23.446-4.673,19.438,5.344,40.2,20.634,53.085,15.241,12.84,35.129,19.341,49.921,32.695a69.641,69.641,0,0,1-23.295,117.3L48.989,373.066C3.222,424.355-2.181,349.674,4.412,307.72"
                transform="translate(9.277 0)"
              />
              <path
                id="Path_3"
                data-name="Path 3"
                d="M23.469,126.024c28.8-22.11,18.881-58.461,7.058-94.152l85.509,49.234c4.538,26.064,5.214,42.922,12.786,56.584,29.725,74.286-1.422,119.015-10.26,126.192s-59.424-17.616-62.69-26.7c-4.192-11.645-19.9-75.4-27.351-85.277s-8.61-14.035-5.052-25.885"
                transform="translate(94.94 140.509)"
                fill="#f49b79"
              />
              <path
                id="Path_4"
                data-name="Path 4"
                d="M18.5,95.769l22.775,20.1c18.27,20.32,35.718,34.918,62.123,41.9,7.393,2,15.42,3.321,22.64.757,9.411-3.288,15.5-12.364,19.909-21.261a146.648,146.648,0,0,0,9.5-103.925,66.856,66.856,0,0,1-33,3.575A67.43,67.43,0,0,1,82.23,15.187c5.16,11.455,5.452,27.5.487,38.574A42.611,42.611,0,0,1,52.44,77.742c-7.1,1.363-14.7.876-21.364,3.71A23.266,23.266,0,0,0,18.5,95.769"
                transform="translate(81.563 66.953)"
                fill="#f49b79"
              />
              <path
                id="Path_5"
                data-name="Path 5"
                d="M48.241,38.67a15.116,15.116,0,0,0-9.606-11.92A15.1,15.1,0,0,0,23.621,29.7c-4.321,3.927-5.966,10.173-5.5,15.993A27.627,27.627,0,0,0,30.242,66.21C37.1,70.742,45.3,74.171,53.109,71.57Z"
                transform="translate(79.568 114.143)"
                fill="#f49b79"
              />
              <path
                id="Path_6"
                data-name="Path 6"
                d="M331.025,229.952c-6.458-66.758-23.771-116.208-44.09-136.533-14.971-14.965-42.9-26.253-63.177-32.468-3.391,43.036-19.557,91.48-19.557,91.48-2.153,4.738-6.025,8.9-11,10.233-8.421,2.25-19.179-2.007-22.862-9.9C156.615,123.5,118.409,49.279,118.409,49.279,71.063,57.889,40.5,76.971,22.094,94.04,16.5,99.254,11.142,104.992,7.75,111.878c-4.879,9.854-5.36,21.234-5.738,32.186C.817,179.5,1.2,159.749,0,195.185c4.976,3.153,9.946,6.263,14.922,9.227,18.7,11.147,41.992,20.26,62.7,19.687L40.169,262.278c.671,9.757,1.579,20.131,2.25,30.748C44,318.089,38.282,359.973,32.927,391.5c100.723,25.929,171.035,25.523,236.943.741-8.7-50.548-16.458-109.734-8.043-160.234,26.924,6.312,45.475,5.6,69.2-2.055"
                transform="translate(0 217.249)"
                fill="#cd5a19"
              />
              <path
                id="Path_7"
                data-name="Path 7"
                d="M72.539,137.626c8.032,24.2,18.422,42.462,27.411,52.268l-5.8,3.575c32.857-23.771,76.417-31.754,105.4-60.208,16.258-15.977,26.686-37.254,43.468-52.7,6.7-6.171,14.825-11.477,23.96-12.05,11.336-.719,21.661,5.976,31.083,12.386l.433.811a16.322,16.322,0,0,1-26.35,9.611c-4.446,7.031-3.488,16.02-3.543,24.344-.043,8.367-2.2,18.027-9.752,21.564-5.263,2.537-11.434,1.2-17.264,1.628-8.275.622-15.874,5.025-22.045,10.617-6.122,5.549-11,12.337-16.02,18.935-32.954,42.944-73.5,71.5-121.038,97.413,0,0-3.683-2.1-8.989-5.928a123.579,123.579,0,0,1-18.605-16.545,179.507,179.507,0,0,1-24.631-34.863c-13.673-24.771-23.381-54.707-27.5-88.614,18.589,9.741,28.773,17.167,61.982,18.5,1.833.07,7.8-.741,7.8-.741"
                transform="translate(12.159 301.797)"
                fill="#f49b79"
              />
              <path
                id="Path_8"
                data-name="Path 8"
                d="M111.518,165.423q-27.162-2.15-54.323-4.305l-1.909.579Q73.19,121.757,91.1,81.812c2.867-6.393,8.41-19.233,8.41-19.233,8.9-.135,14.89-.011,20.6.146q25.563.714,51.122,1.422-8.527,18.465-17.053,36.935"
                transform="translate(243.727 275.628)"
                fill="#909090"
              />
              <path
                id="Path_9"
                data-name="Path 9"
                d="M122.111,119.462c1.958-2.71,1.942-5.344.936-8.529q-5.135-16.307-10.282-32.614L75.392,68.438l.93.341c-3.083-1.044-6.528,1.038-8.124,3.878s-1.806,6.22-1.974,9.476q-.479,9.249-.957,18.5c-.27,5.2-.525,10.53,1.076,15.479a22,22,0,0,0,20.331,14.819"
                transform="translate(286.851 301.712)"
                fill="#f49b79"
              />
            </g>
          </g>
          <g
            id="Mask_Group_2"
            data-name="Mask Group 2"
            clipPath="url(#clip-path-3)"
          >
            <g
              id="Klaasje_1-2"
              data-name="Klaasje 1"
              transform="translate(188.523 271.862)"
              clipPath="url(#clip-path-2)"
            >
              <path
                id="Path_21"
                data-name="Path 21"
                d="M118.972,137.9,108.739,150.25c17.1-21.921,43.436-45.032,66.26-60.911l35.437-11.466a359.931,359.931,0,0,1-91.989,142.342c-5.111,4.846-10.547,9.649-17.156,12.072-14.452,5.295-36.226-12.142-40.369-26.962l-15.89-2.461L59.738,100.913l57.185,2.288c.584,9.324,1.466,25.366,2.05,34.7"
                transform="translate(198.526 343.307)"
                fill="#f49b79"
              />
              <path
                id="Path_22"
                data-name="Path 22"
                d="M4.412,307.72c1.806-11.509,3.575-22.938,7.729-33.825s8.47-22.061,8.356-33.717c-.189-19.487-12.678-39.271-5.517-57.4,3.245-8.221,10.282-14.89,11.764-23.6,1.174-6.9-1.368-13.813-3.489-20.482-5.684-17.832-8.627-36.875-5.582-55.34s12.58-36.318,28.011-46.908,36.994-12.77,53.052-3.164A100.418,100.418,0,0,1,258.516,47.071c7.956,12.694,13.078,28.076,9.789,42.7-1.817,8.064-6.074,15.409-8,23.446-4.673,19.438,5.344,40.2,20.634,53.085,15.241,12.84,35.129,19.341,49.921,32.695a69.641,69.641,0,0,1-23.295,117.3L48.989,373.066C3.222,424.355-2.181,349.674,4.412,307.72"
                transform="translate(9.277 0)"
              />
              <path
                id="Path_23"
                data-name="Path 23"
                d="M23.469,126.024c28.8-22.11,18.881-58.461,7.058-94.152l85.509,49.234c4.538,26.064,5.214,42.922,12.786,56.584,29.725,74.286-1.422,119.015-10.26,126.192s-59.424-17.616-62.69-26.7c-4.192-11.645-19.9-75.4-27.351-85.277s-8.61-14.035-5.052-25.885"
                transform="translate(94.94 140.509)"
                fill="#f49b79"
              />
              <path
                id="Path_24"
                data-name="Path 24"
                d="M18.5,95.769l22.775,20.1c18.27,20.32,35.718,34.918,62.123,41.9,7.393,2,15.42,3.321,22.64.757,9.411-3.288,15.5-12.364,19.909-21.261a146.648,146.648,0,0,0,9.5-103.925,66.856,66.856,0,0,1-33,3.575A67.43,67.43,0,0,1,82.23,15.187c5.16,11.455,5.452,27.5.487,38.574A42.611,42.611,0,0,1,52.44,77.742c-7.1,1.363-14.7.876-21.364,3.71A23.266,23.266,0,0,0,18.5,95.769"
                transform="translate(81.563 66.953)"
                fill="#f49b79"
              />
              <path
                id="Path_25"
                data-name="Path 25"
                d="M48.241,38.67a15.116,15.116,0,0,0-9.606-11.92A15.1,15.1,0,0,0,23.621,29.7c-4.321,3.927-5.966,10.173-5.5,15.993A27.627,27.627,0,0,0,30.242,66.21C37.1,70.742,45.3,74.171,53.109,71.57Z"
                transform="translate(79.568 114.143)"
                fill="#f49b79"
              />
              <path
                id="Path_26"
                data-name="Path 26"
                d="M331.025,229.952c-6.458-66.758-23.771-116.208-44.09-136.533-14.971-14.965-42.9-26.253-63.177-32.468-3.391,43.036-19.557,91.48-19.557,91.48-2.153,4.738-6.025,8.9-11,10.233-8.421,2.25-19.179-2.007-22.862-9.9C156.615,123.5,118.409,49.279,118.409,49.279,71.063,57.889,40.5,76.971,22.094,94.04,16.5,99.254,11.142,104.992,7.75,111.878c-4.879,9.854-5.36,21.234-5.738,32.186C.817,179.5,1.2,159.749,0,195.185c4.976,3.153,9.946,6.263,14.922,9.227,18.7,11.147,41.992,20.26,62.7,19.687L40.169,262.278c.671,9.757,1.579,20.131,2.25,30.748C44,318.089,38.282,359.973,32.927,391.5c100.723,25.929,171.035,25.523,236.943.741-8.7-50.548-16.458-109.734-8.043-160.234,26.924,6.312,45.475,5.6,69.2-2.055"
                transform="translate(0 217.249)"
                fill="#cd5a19"
              />
              <path
                id="Path_27"
                data-name="Path 27"
                d="M72.539,137.626c8.032,24.2,18.422,42.462,27.411,52.268l-5.8,3.575c32.857-23.771,76.417-31.754,105.4-60.208,16.258-15.977,26.686-37.254,43.468-52.7,6.7-6.171,14.825-11.477,23.96-12.05,11.336-.719,21.661,5.976,31.083,12.386l.433.811a16.322,16.322,0,0,1-26.35,9.611c-4.446,7.031-3.488,16.02-3.543,24.344-.043,8.367-2.2,18.027-9.752,21.564-5.263,2.537-11.434,1.2-17.264,1.628-8.275.622-15.874,5.025-22.045,10.617-6.122,5.549-11,12.337-16.02,18.935-32.954,42.944-73.5,71.5-121.038,97.413,0,0-3.683-2.1-8.989-5.928a123.579,123.579,0,0,1-18.605-16.545,179.507,179.507,0,0,1-24.631-34.863c-13.673-24.771-23.381-54.707-27.5-88.614,18.589,9.741,28.773,17.167,61.982,18.5,1.833.07,7.8-.741,7.8-.741"
                transform="translate(12.159 301.797)"
                fill="#f49b79"
              />
              <path
                id="Path_28"
                data-name="Path 28"
                d="M111.518,165.423q-27.162-2.15-54.323-4.305l-1.909.579Q73.19,121.757,91.1,81.812c2.867-6.393,8.41-19.233,8.41-19.233,8.9-.135,14.89-.011,20.6.146q25.563.714,51.122,1.422-8.527,18.465-17.053,36.935"
                transform="translate(243.727 275.628)"
              />
              <path
                id="Path_29"
                data-name="Path 29"
                d="M122.111,119.462c1.958-2.71,1.942-5.344.936-8.529q-5.135-16.307-10.282-32.614L75.392,68.438l.93.341c-3.083-1.044-6.528,1.038-8.124,3.878s-1.806,6.22-1.974,9.476q-.479,9.249-.957,18.5c-.27,5.2-.525,10.53,1.076,15.479a22,22,0,0,0,20.331,14.819"
                transform="translate(286.851 301.712)"
                fill="#f49b79"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default Klaasje;
