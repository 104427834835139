import { NavLink } from "react-router-dom";
import './Navigation.scss';

function NavigationItem(props) {

  return (
    <NavLink className="NavigationItem" to={props.to}>
      <div className={"NavigationItem__Icon NavigationItem__Icon--" + props.icon}></div>
      <div className="NavigationItem__Text">{props.title}</div>
    </NavLink>
  );
}

export default NavigationItem;
