import { createRef, useEffect, useLayoutEffect } from "react";
import { gsap, Power2 } from "gsap/gsap-core";
import "./Navigation.scss";

function NavigationBar(props) {
  const navBarRef = createRef();

  function onResize() {
    document.querySelector(':root').style.setProperty('--navbarCurrentHeight', `${navBarRef.current.clientHeight}px`);
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [])

  useEffect(() => {
    if (props.visible) {
      gsap.to(navBarRef.current, {duration: 0.2, y: 0, ease: Power2.easeOut});
      // gsap.to(props.dynamicHeightContainer.current, {paddingBottom: navBarRef.current.clientHeight + 20, ease: Power2.easeOut});
      document.querySelector(':root').style.setProperty('--navbarCurrentHeight', `${navBarRef.current.clientHeight}px`);
    } else {
      gsap.to(navBarRef.current, {duration: 0.2, y: '100%', ease: Power2.easeOut});
      // gsap.to(props.dynamicHeightContainer.current, {paddingBottom: 20, ease: Power2.easeOut});
      document.querySelector(':root').style.setProperty('--navbarCurrentHeight', '0px');

    }
  }, [props.visible, navBarRef]);

  useLayoutEffect(() => {
    if (!props.visible) {
      gsap.set(navBarRef.current, {y: '100%'});
      document.querySelector(':root').style.setProperty('--navbarCurrentHeight', '0px');
    }
  }, [])

  return (
    <div className="NavigationBar" ref={navBarRef}>
      <div className="NavigationBar__Content">{props.children}</div>
    </div>
  );
}

export default NavigationBar;
