import "./Intake.scss";

import React, { useEffect, useLayoutEffect, useState } from "react";
import { getQuestions } from "../../firebase-config";
import { useGlobalContext } from "../../context/store";
import Loading from "../Loading/Loading";
import Input from "../Input/Input";
import { findAndReplace } from "../../helpers/propertyReplacer";
import { useNavigate } from "react-router-dom";
import { usePrompt } from "../../helpers/prompt-blocker";
import { saveIntakeData } from "../../context/user/actions";

function Intake() {
  const [state, dispatch] = useGlobalContext();

  const progressBarRef = React.createRef();
  const conversationFlowComponent = React.createRef();

  const [isLoading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(false);
  const [flow, setFlow] = useState([]);
  const [intakeData, setIntakeData] = useState({});
  const [dataIsSaved, setDataSaved] = useState(false);

  usePrompt(
    "Weet je zeker dat je wilt stoppen met de intake? De gegevens worden niet opgeslagen.",
    !dataIsSaved
  );

  const navigate = useNavigate();

  const inputApi = {
    onAnswerGiven,
    setFlowSize,
  };

  if (currentQuestion === false) {
    setCurrentQuestion(0);
    getQuestions().then((resp) => {
      setQuestions(resp);
      setFlow([getQuestionById(0, resp)]);
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!state.user.profile) return;
    setLoading(state.user.loading);

    if (dataIsSaved) navigate("/home");
  }, [
    state,
    state.user.loading,
    state.user.profile,
    currentQuestion,
    dataIsSaved,
    navigate,
  ]);

  useLayoutEffect(() => {
    let answers = getQuestionById(currentQuestion)?.answers;
    if (!answers) setFlowSize(0);
  });

  useLayoutEffect(() => {
    const currentConversationContainer = conversationFlowComponent.current;
    if (currentConversationContainer)
      currentConversationContainer.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  }, [conversationFlowComponent, flow]);

  function setFlowSize(height) {
    const currentConversationContainer = conversationFlowComponent.current;
    if (!currentConversationContainer) return;
    currentConversationContainer.style.paddingBottom = `${height}px`;
    currentConversationContainer.scrollIntoView(false);
  }

  function getProgress() {
    return currentQuestion / (questions.length - 1);
  }

  async function onAnswerGiven(answer) {
    answer.type = "answer";
    switch (answer.event.type) {
      case "nextQuestion":
        window.dataLayer.push({ event_params: null });
        window.dataLayer.push({
          event: "interaction",

          event_params: {
            event_name: "lets_go",
          },
        });
        setCurrentQuestion(answer.event.nextQuestionId);
        setFlow([
          ...flow,
          answer,
          getQuestionById(answer.event.nextQuestionId),
        ]);
        break;
      case "saveAndNextQuestion":
        window.dataLayer.push({ event_params: null });
        window.dataLayer.push({
          event: "interaction",
          event_params: {
            event_name: "question_asked",
            question: getQuestionById(currentQuestion).content.text.join(" "),
            answer: answer.value,
          },
        });
        await setIntakeData({
          ...intakeData,
          [answer.event.property]: answer.value,
        });

        setCurrentQuestion(answer.event.nextQuestionId);
        setFlow([
          ...flow,
          answer,
          getQuestionById(answer.event.nextQuestionId),
        ]);
        break;
      case "endIntake":
        dispatch(saveIntakeData(intakeData)).then(() => {
          setDataSaved(true);
        });

        break;
      default:
        console.warn("Clickhandle type not defined");
        break;
    }
  }

  function getQuestionById(id, _questions) {
    if (_questions)
      return (
        _questions.find((question) => question.questionId === Number(id)) ||
        null
      );
    return (
      questions.find((question) => question.questionId === Number(id)) || null
    );
  }

  function renderItem(item, groupIndex) {
    if (!item) return;
    let renderedItem = [];
    if (item.type === "answer") {
      item.content.forEach((text, bubbleIndex) => {
        renderedItem.push(
          <div className="chatBubble" key={"chatBubble_" + bubbleIndex}>
            <span className="text">{text}</span>
          </div>
        );
      });
      return (
        <div
          className="bubbleGroup bubbleGroup--answer"
          key={"bubbleGroup_" + groupIndex}
        >
          {renderedItem}
        </div>
      );
    } else {
      item.content.text.forEach((text, bubbleIndex) => {
        renderedItem.push(
          <div className="chatBubble" key={"chatBubble_" + bubbleIndex}>
            <span className="text">{findAndReplace(text, state)}</span>
          </div>
        );
      });

      return (
        <div className="bubbleGroup" key={"bubbleGroup_" + groupIndex}>
          {renderedItem}
        </div>
      );
    }
  }

  function renderConversation(flow) {
    if (!flow || flow.length < 1) return;
    let renderedFlow = [];
    flow.forEach((flowItem, index) => {
      renderedFlow.push(renderItem(flowItem, index));
    });

    return (
      <div className="conversationFlow" ref={conversationFlowComponent}>
        {renderedFlow}
      </div>
    );
  }

  function renderAnswerModule(currentQuestion) {
    let answers = getQuestionById(currentQuestion)?.answers;
    if (!answers) return;
    return (
      <Input
        type={answers.type}
        answers={answers}
        api={inputApi}
        key={`input_component_${currentQuestion}`}
      ></Input>
    );
  }

  return (
    <div className="Intake">
      <Loading isLoading={isLoading}></Loading>
      <div className="Intake__chatHeader">
        <div className="Intake__chatHeader__klaasje"></div>
        <div className="Intake__chatHeader__textContainer">
          <h2 className="Intake__chatHeader__textContainer__title">
            Chat met Klaasje
          </h2>
          <p className="Intake__chatHeader__textContainer__subtitle">
            Jouw persoonlijke slaapcoach
          </p>
        </div>
        <div className="Intake__chatHeader__progressBar">
          <div
            className="Intake__chatHeader__progressBar__progress"
            style={{ width: getProgress() * 100 + "%" }}
            ref={progressBarRef}
          ></div>
        </div>
      </div>
      {renderConversation(flow)}
      {renderAnswerModule(currentQuestion)}
    </div>
  );
}

export default Intake;
