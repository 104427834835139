import { useContext } from "react";
import {
  createReducerFactory,
  createContextFactory,
} from "react-use-reduxlike-reducer";

import userReducer from "./user/reducer";
import userState from "./user/state";
import ThemeState from "./theme/state";
import ThemeReducer from "./theme/reducer";

const globalState = {
  user: userState,
  theme: ThemeState,
};

const combineReducers = (reducers) => (state, action) => {
  return Object.keys(reducers).reduce(
    (tempState, key) => {
      return {
        ...tempState,
        [key]: reducers[key](tempState[key], action),
      };
    },
    { ...state }
  );
};

export const GlobalContext = createContextFactory(globalState);

export const useGlobalContext = () => useContext(GlobalContext);

const useGlobalReducer = createReducerFactory(
  combineReducers({
    user: userReducer,
    theme: ThemeReducer,
  }),
  globalState
);

const Store = ({ children }) => {
  const [store, dispatchAsync] = useGlobalReducer();
  return (
    <GlobalContext.Provider value={[store, dispatchAsync]}>
      {children}
    </GlobalContext.Provider>
  );
};

export const withStore = (Component, props) => (
  <Store>
    <Component {...props} />
  </Store>
);

export default Store;
