import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Loading/Loading";
import Klaasje from "../Klaasje/Klaasje";
import "./Form.scss";
import { useGlobalContext } from "../../context/store";
import {
  signingIn,
  signingInWithGoogle,
  setErrorMessage,
} from "../../context/user/actions";
import { getUser } from "../../firebase-config";
import { TosModal } from "../../Views/Tos/Tos";

function Form() {
  const [isLoading, setLoading] = useState(true);
  const [state, dispatch] = useGlobalContext();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setLoading(state.user.loading);
    if (state.user.profile) {
      navigate("/home");
    }
    if (!getUser()) {
      setLoading(false);
    }
  }, [state.user.loading, state.user.profile, navigate]);
  return (
    <div className="Form">
      <Klaasje></Klaasje>
      <div className="Form__container">
        <Loading isLoading={isLoading}></Loading>
        <input
          type="text"
          className="Form__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Je e-mailadres"
        />
        <input
          type="password"
          className="Form__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Je wachtwoord"
        />
        {state.user.error && (
          <div className="Form__errorField">{state.user.error}</div>
        )}
        <button
          className="Form__btn"
          onClick={() => {
            if (!password) {
              dispatch(setErrorMessage("Er is geen wachtwoord ingevuld."));
              return;
            }
            TosModal()
              .then(() => {
                dispatch(signingIn(email, password)).then(function () {
                  navigate("/home");
                });
              })
              .catch(() => {});
          }}
        >
          Inloggen
        </button>
        <button
          className="Form__btn Form__google"
          onClick={() => {
            TosModal()
              .then(() => {
                dispatch(signingInWithGoogle()).then(function () {
                  navigate("/home");
                });
              })
              .catch(() => {});
          }}
        >
          <div className="Google__logo"></div>
          <span>Inloggen met Google</span>
        </button>
        <div className="Form__label">Nog geen account?</div>
        <button
          className="Form__btn Form__btn--secondary"
          onClick={() => {
            dispatch(setErrorMessage(""));
            navigate("/register");
          }}
        >
          Account aanmaken
        </button>
        <div className="Form__label">
          <Link
            to="/reset"
            className="Form__link"
            onClick={() => {
              dispatch(setErrorMessage(""));
            }}
          >
            Wachtwoord vergeten
          </Link>
        </div>
      </div>
    </div>
  );
}
export default Form;
