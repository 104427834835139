import {
  getUser,
  logInWithEmailAndPassword,
  logout,
  saveIntake,
  registerWithEmailAndPassword,
  saveUserProfile,
  signInWithGoogle,
} from "../../firebase-config";

import { authChecker } from "../../helpers/authChecker";

export const SIGNING_IN_USER = "SIGNING_IN_USER";
export const SIGNING_IN_WTIH_GOOGLE_USER = "SIGNING_IN_WTIH_GOOGLE_USER";
export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTERING_USER = "REGISTERING_USER";
export const REGISTERING_USER_FAILED = "REGISTERING_USER_FAILED";
export const SIGNING_OUT_USER = "SIGNING_OUT_USER";
export const SIGNED_OUT = "SIGNED_OUT";
export const SETTING_USER = "SETTING_USER";
export const SIGNING_IN_FAILED = "SIGNING_IN_FAILED";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const SET_PROFILE_DATA_FAILED = "SET_PROFILE_DATA_FAILED";

export const signingIn = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: SIGNING_IN_USER });
    const user = await logInWithEmailAndPassword(email, password);
    // console.log(user);
    return dispatch({ type: SETTING_USER, payload: user });
  } catch (e) {
    // console.log(e);
    dispatch({ type: SIGNING_IN_FAILED, payload: authChecker(e.message) });
  }
};

export const signingInWithGoogle = () => async (dispatch) => {
  try {
    dispatch({ type: SIGNING_IN_WTIH_GOOGLE_USER });
    const user = await signInWithGoogle();
    // console.log(user);
    return dispatch({ type: SETTING_USER, payload: user });
  } catch (e) {
    dispatch({ type: SIGNING_IN_FAILED, payload: authChecker(e.code) });
  }
};

export const signingOut = () => async (dispatch) => {
  dispatch({ type: SIGNING_OUT_USER });

  await logout();
  dispatch({ type: SIGNED_OUT, payload: undefined });
};

export const populateUser = () => async (dispatch) => {
  const user = await getUser().catch(null);

  if (!user) {
    // clear auth state
  }

  return dispatch({ type: SETTING_USER, payload: user });
};

export const registerUser = (name, email, password) => async (dispatch) => {
  try {
    dispatch({ type: REGISTERING_USER });
    const user = await registerWithEmailAndPassword(name, email, password);
    dispatch({ type: REGISTER_USER, payload: user });
  } catch (e) {
    dispatch({ type: REGISTERING_USER_FAILED, payload: authChecker(e.code) });
  }
};

export const setErrorMessage = (message) => async (dispatch) => {
  dispatch({ type: VALIDATION_ERROR, payload: message });
};

export const setUserProfile = (payload) => async (dispatch) => {
  try {
    const userDoc = await saveUserProfile(payload);
    return dispatch({ type: SET_PROFILE_DATA, payload: userDoc });
  } catch (e) {
    return dispatch({
      type: SET_PROFILE_DATA_FAILED,
      payload: authChecker(e.code),
    });
  }
};

export const saveIntakeData = (intakeData) => async (dispatch) => {
  try {
    // TODO: Remove this limitation when allowed by legal
    // const filteredIntakeData = {
    //   sleepGoals: intakeData.sleepGoals,
    // }
    await saveIntake(intakeData);
    let user = await getUser();
    user.intakeData = intakeData;
    return dispatch({ type: SETTING_USER, payload: user });
  } catch (e) {}
};

export const reset = () => async (dispatch) => {
  await logout();
  dispatch({ type: SIGNED_OUT, payload: undefined });
};
