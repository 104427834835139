import ContentBlock from "../../Components/ContentBlock/ContentBlock";
import Setting from "../../Components/Setting/Setting";
import { useGlobalContext } from "../../context/store";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import "./Profile.scss";
import { switchTheme } from "../../context/theme/actions";
import { useState } from "react";
import { useOnNavigation } from "../../helpers/prompt-blocker";
import Loading from "../../Components/Loading/Loading";
import { setUserProfile, signingOut } from "../../context/user/actions";
import { localStorage } from "../../context/localStorage/localStorage";
import { themeChecker } from "../../helpers/themeChecker";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
// import { deleteUserFromApp } from "../../firebase-config";

const onShouldSave = async (payload) =>
  new Promise(async (resolve, reject) => {
    if (!payload.isDirty) return;
    payload.setSaving(true);
    payload.setDirty(false);
    await payload.dispatch(setUserProfile(payload.dirtySettings));
    toast.success("Instellingen opgeslagen");
    resolve();
  });

const addOrRemoveFromArray = (arr, item) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];

function Profile() {
  const [state, dispatch] = useGlobalContext();
  const [isDirty, setDirty] = useState(false);
  const [dirtySettings, setDirtySettings] = useState(state.user.profile);
  const [isSaving, setSaving] = useState(false);
  const [darkModeToggleActive, setDarkModeToggleActive] = useState(
    state.theme.theme !== "light"
  );

  useOnNavigation(isDirty, onShouldSave, {
    setDirty,
    setSaving,
    dispatch,
    dirtySettings,
    isSaving,
    isDirty,
  });

  function changeSetting(structure) {
    setDirty(true);
    setDirtySettings({ ...state.user.profile, ...dirtySettings, ...structure });
  }

  return (
    <div className="Profile">
      <Loading
        isLoading={isSaving}
        size="small"
        message="Even opslaan..."
      ></Loading>
      <div className="Profile__content">
        <ContentBlock
          title="Mijn profiel"
          copy="Pas hier alle relevante instellingen van de app aan."
        />
        <ContentBlock title="Mijn gegevens"></ContentBlock>
        <Setting
          type="input"
          name="name"
          label="Naam"
          placeholder="Voer je naam in"
          icon={faPen}
          value={state.user.profile.name}
          onChange={(newName) => {
            changeSetting({ name: newName });
          }}
        />
        <Setting
          type="input"
          name="email"
          label="E-mailadres"
          placeholder="Voer je e-mailadres in"
          icon={faPen}
          value={state.user.profile.email}
          disabled
        />
        <ContentBlock title="Mijn doelen"></ContentBlock>
        <Setting
          type="boolean"
          name="target_calm_nights"
          label="Rustigere nachten"
          value={state.user.profile?.intakeData?.sleepGoals?.includes(
            "calmerNights"
          )}
          onChange={() => {
            changeSetting({
              intakeData: {
                ...dirtySettings.intakeData,
                sleepGoals: addOrRemoveFromArray(
                  dirtySettings.intakeData.sleepGoals,
                  "calmerNights"
                ),
              },
            });
          }}
        />
        <Setting
          type="boolean"
          name="target_energy"
          label="Meer energie en concentratie overdag"
          value={state.user.profile?.intakeData?.sleepGoals?.includes("energy")}
          onChange={() => {
            changeSetting({
              intakeData: {
                ...dirtySettings.intakeData,
                sleepGoals: addOrRemoveFromArray(
                  dirtySettings.intakeData.sleepGoals,
                  "energy"
                ),
              },
            });
          }}
        />
        <Setting
          type="boolean"
          name="target_health"
          label="Ik wil mijn levensstijl verbeteren"
          value={state.user.profile?.intakeData?.sleepGoals?.includes("health")}
          onChange={() => {
            changeSetting({
              intakeData: {
                ...dirtySettings.intakeData,
                sleepGoals: addOrRemoveFromArray(
                  dirtySettings.intakeData.sleepGoals,
                  "health"
                ),
              },
            });
          }}
        />
        <Link to="/intake">
          <Setting
            type="button"
            name="log_out"
            label="Intake opnieuw"
            buttonStyle="outline fill-width"
            onClick={() => {}}
          />
        </Link>
        {/* <ContentBlock title="Notificaties"></ContentBlock>
        <Setting
          type="boolean"
          name="notifications"
          label="Push notificaties"
          value={dirtySettings.settings?.notifications || false}
          onChange={(value) => {
            changeSetting({
              settings: { ...dirtySettings.settings, notifications: value },
            });
          }}
        />
        <Setting
          type="boolean"
          name="daily_reminder"
          label="Dagelijkse reminder"
          value={dirtySettings.settings?.dailyReminder || false}
          onChange={(value) => {
            changeSetting({
              settings: { ...dirtySettings.settings, dailyReminder: value },
            });
          }}
        />
        <Setting
          type="boolean"
          name="newsletter"
          label="Nieuwsbrief"
          value={dirtySettings.settings?.newsLetter || false}
          onChange={(value) => {
            changeSetting({
              settings: { ...dirtySettings.settings, newsLetter: value },
            });
          }}
          disabled
        /> */}
        <ContentBlock title="Donkere modus"></ContentBlock>
        <Setting
          type="select"
          name="auto_dark_mode"
          label="Automatische donkere modus"
          options={[
            {
              value: "off",
              label: "Uit",
            },
            {
              value: "os",
              label: "Volg OS",
            },
            {
              value: "timeOfDay",
              label: "Tijd van dag",
            },
          ]}
          value={state.user.profile?.settings?.autoDarkMode || "timeOfDay"}
          onChange={(newValue) => {
            localStorage.autoTheme.set(newValue);
            themeChecker.onTypeChanged();
            if (newValue === "off")
              dispatch(switchTheme(darkModeToggleActive ? "dark" : "light"));
            changeSetting({
              settings: { ...dirtySettings.settings, autoDarkMode: newValue },
            });
          }}
        />
        {dirtySettings.settings?.autoDarkMode === "off" && (
          <Setting
            type="boolean"
            name="dark_mode"
            label="Donkere modus"
            value={state.theme.theme !== "light"}
            onChange={function (isDark) {
              dispatch(switchTheme(isDark ? "dark" : "light"));
              changeSetting({
                settings: {
                  ...dirtySettings.settings,
                  currentTheme: isDark ? "dark" : "light",
                },
              });
              setDarkModeToggleActive(isDark);
            }}
          />
        )}
        {/* <ContentBlock title="Overig"></ContentBlock>
        <Setting
          type="link"
          name="contact"
          label="Contact & feedback"
          to="contact"
        />
        <Setting
          type="link"
          name="qa"
          label="Veelgestelde vragen"
          to="vragen"
        /> */}

        <ContentBlock title="Account"></ContentBlock>
        {/* <div className="flex__container --horizontal --justify"> */}
        <Setting
          type="button"
          name="log_out"
          label="Uitloggen"
          buttonStyle="outline fill-width"
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            const shouldSignout = confirm(
              "Weet je zeker dat je wilt uitloggen?"
            );
            if (shouldSignout) dispatch(signingOut());
          }}
        />
        {/* <Setting
            type="button"
            name="delete_account"
            label="Account verwijderen"
            buttonStyle="outline color--red fill-width"
            onClick={async () => {
              // eslint-disable-next-line no-restricted-globals
              const emailInput = prompt('Om zeker te zijn dat je je account niet per ongeluk verwijderd, vragen we je om je e-mailadres nogmaals in te vullen.');
              if (emailInput === state.user.profile.email) {
                await deleteUserFromApp();
                dispatch(reset());
              }
            }}
          /> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default Profile;
