import gsap, { Power2 } from "gsap/all";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { create } from "react-modal-promise";
import Button from "../../Components/Button/Button";
import { localStorage } from "../../context/localStorage/localStorage";
import "./Tos.scss";
import Klaasje from "../../Components/Klaasje/Klaasje";
function Tos({ isOpen, onResolve, onReject }) {
  const tosRef = useRef();

  const [isAccepted, setAccepted] = useState(localStorage.terms.get());

  useEffect(() => {
    if (isAccepted) onResolve();
  }, [isAccepted, onResolve]);

  useLayoutEffect(() => {
    if (isOpen) {
      gsap.from(tosRef.current, {
        duration: 0.5,
        y: "100%",
        ease: Power2.easeOut,
      });
    }
  });

  if (!isOpen || isAccepted) return <></>;

  function onClickedReject() {
    const retry = window.confirm(
      "Je moet de servicevoorwaarden accepteren om de app te kunnen gebruiken."
    );
    if (!retry) onReject();
  }

  return (
    <div className="Tos" ref={tosRef}>
      <div className="Tos__content">
        <h2>Terms of service</h2>
        <p className="Tos__content__subline Tos__content__subline--highlight">
          Laatst gewijzigd: december 2022
        </p>
        <p>
          <i>
            Lees deze overeenkomst zorgvuldig voordat je gebruik maakt van deze
            app.
          </i>
        </p>
        <h3>Privacyverklaring</h3>
        <p>
          Uw privacy is een serieuze zaak. Omdat wij persoonsgegevens van u
          ontvangen en verwerken wanneer u gebruik maakt van de M line
          slaapcoach applicatie (Slaap-app), vinden wij het belangrijk om u te
          laten weten wat er met die gegevens gebeurt.
        </p>
        <p>
          Wij respecteren uw privacy en doen er dan ook alles aan om uw
          privégegevens ook echt privé te houden. De manier waarop wij met uw
          gegevens omgaan is geheel volgens de geldende (Europese) privacy
          wetgeving. In deze privacyverklaring leggen wij uit welke gegevens wij
          van u verzamelen en voor welk doel wij dit doen. Daarnaast lichten wij
          toe hoe wij omgaan met uw persoonsgegevens en wat uw rechten op dat
          gebied zijn.
        </p>
        <p>
          Deze privacyverklaring is van toepassing op de Slaap-app. Informatie
          over andere verwerkingen van persoonsgegevens die wij doen,
          bijvoorbeeld wanneer u onze website (mline.nl / mline.be) bezoekt,
          kunt u hier vinden.
        </p>
        <p>
          Wie is verantwoordelijk voor de verwerking van uw persoonsgegevens?
        </p>
        <p>
          Dit is de privacyverklaring van M line’s moederbedrijf DBC
          International B.V. (“DBC”). DBC is ingeschreven bij de Kamer van
          Koophandel onder het nummer 17134755 en is gevestigd aan de Linie 27
          in (5405 AR) Uden.
        </p>
        <h4>Welke persoonsgegevens verwerken wij en met welk doel?</h4>
        <h5> Account</h5>
        <ul>
          <li>Voor- en achternaam</li>
          <li>E-mailadres</li>
          <li>Wachtwoord</li>
          <li>Slaapdoelen</li>
        </ul>
        <p>
          Deze gegevens gebruiken wij om voor u een account aan te maken in de
          Slaap-app. Middels uw account doorloopt u de vragen van de digitale
          slaapcoach (zie hieronder). Via uw account krijgt u daarna tips,
          wist-je-datjes en oefeningen beschikbaar en kunt u deelnemen aan uw
          challenges, gebaseerd op uw slaapdoelen. In uw account kunt u uw
          gegevens en slaapdoelen wijzigen.
        </p>
        <p>
          Voor het aanbieden van de Slaap-app maken wij gebruik van de
          dienstverlening van Google.
        </p>
        <h5>Slaapcoach</h5>
        <ul>
          <li>Uw reden voor het verbeteren van uw slaapprestaties</li>
          <li>Gemiddelde tijd waarop u naar bed gaat en opstaat</li>
          <li>Informatie over uw gemiddelde slaapritme</li>
          <li>
            Bijhouden van de voortgang van challenges waar u aan deelneemt
          </li>
        </ul>
        Op basis van de gegeven antwoorden toont Slaap-app tips, wist-je-datjes
        en oefeningen die passen bij uw antwoorden over uw slaaprite, gewoonten
        en behoefte. Ook kunt u deelnemen aan individuele challenges, waarbij uw
        voortgang wordt getoond in uw account in de Slaap-app.
        <h5>Gebruik van de Slaap-app</h5>
        <ul>
          <li>IP-adres</li>
          <li>Technische informatie over uw gebruik van de Slaap-app</li>
        </ul>
        <p>
          Wij gebruiken Google Analytics om het gebruik van onze Slaap-app in
          kaart te brengen en te analyseren. Deze informatie gebruiken wij om de
          Slaap-app veilig te houden en verbeteringen aan te brengen. Voor meer
          informatie zie hoofdstuk{" "}
          <span className="underline">“Welke cookies gebruiken wij?”</span>
        </p>
        <h4>
          Wat is de juridische grondslag voor het gebruik van uw gegevens?{" "}
        </h4>
        <p>
          Wij mogen alleen rechtmatig persoonsgegevens van u verwerken als wij
          dat doen op basis van een juridische grondslag. Voor het verwerken van
          uw persoonsgegevens in de Slaap-app hebben wij de volgende
          grondslagen:
        </p>
        <ul className="spaced">
          <li>
            <b>Uitvoering overeenkomst:</b> voor het verlenen van de gevraagde
            dienstverlening aan u in de Slaap-app, zoals het aanmaken van een
            account en het verstrekken van tips, wist-je-datjes, oefeningen en
            challenges, is het noodzakelijk dat wij uw persoonsgegevens
            verwerken.
          </li>
          <li>
            <b>Gerechtvaardigd belang:</b> om te zorgen dat de Slaap-app
            optimaal blijft werken analyseren we uw gebruik van de Slaap-app. Zo
            kunnen we de Slaap-app veilig houden en verbeteringen in de
            gebruikservaring aanbrengen.
          </li>
        </ul>
        <h4>Geven wij uw gegevens door buiten de EU?</h4>
        <p>
          Als uitgangspunt verwerken wij al uw gegevens binnen de Europese Unie
          (EU).{" "}
        </p>
        <p>
          Mocht dit noodzakelijk zijn, dan kan DBC uw gegevens (doen) verwerken
          buiten de EU. Het niveau van bescherming van persoonsgegevens kan in
          deze landen lager zijn dan wij kennen in de EU. Om uw privacy te
          waarborgen, zal DBC in voorkomend geval de maatregelen treffen die
          nodig zijn om de bescherming van uw persoonsgegevens te waarborgen.
        </p>
        <p>
          Voor meer informatie neemt u contact met ons op via de contactgegevens
          onderaan deze privacyverklaring.
        </p>
        <h4>Hoe lang worden uw gegevens bewaard?</h4>
        <p>
          We bewaren uw gegevens niet langer dan noodzakelijk voor de doeleinden
          waarvoor uw gegevens worden verzameld en verwerkt. Daarna verwijderen
          of anonimiseren we de gegevens die we van u hebben. Concreet hanteren
          we de volgende bewaartermijnen:
        </p>
        <ul className="spaced">
          <li>
            <b>Account:</b> uw account gegevens bewaren wij voor zolang uw
            account actief is. Wanneer u uw account verwijdert, dan verwijderen
            wij uw gegevens binnen [1 maand]. U kunt in uw account ook uw
            account gegevens tussentijds wijzigen of verwijderen.{" "}
          </li>
          <li>
            <b>Slaapcoach:</b> de antwoorden die u geeft aan de digitale
            slaapcoach worden binnen [24 uur] geaggregeerd met antwoorden van
            andere gebruikers, zodat deze niet meer zijn te herleiden tot een
            individu (anoniem zijn). De oorspronkelijke antwoorden worden daarna
            verwijderd. Dit betekent dat deze gegevens maximaal [24 uur] worden
            bewaard.
          </li>
          <li>
            <b>Gebruik van de Slaap-app:</b> de technische informatie over uw
            gebruik van de Slaap-app wordt verzameld via een aantal cookies met
            elk een eigen functie en bewaartermijn. In het hoofdstuk “Welke
            cookies gebruiken wij?” staat opgenomen welke cookies wij precies
            gebruiken, de functie en de bewaartermijn.
          </li>
        </ul>
        <p>
          Voor meer informatie neemt u contact met ons op via de contactgegevens
          onderaan deze privacyverklaring.
        </p>
        <h4>Welke cookies gebruiken wij?</h4>
        <p>
          Wij maken binnen de Slaap-app gebruik van Google Analytics om het
          gebruik van de Slaap-app te analyseren.
        </p>
        <p>
          Wij hebben Google Analytics privacy vriendelijk ingesteld. Dit houdt
          onder meer in dat het IP-adres wordt gepseudonimiseerd voordat dit
          wordt verzonden aan Google. Daarnaast hebben wij met Google een
          verwerkersovereenkomst gesloten op grond waarvan het Google niet is
          toegestaan deze gegevens aan derden te verstrekken of voor andere
          Google-diensten in te zetten. De maximale bewaartermijn van de cookies
          die middels deze dienst worden gebruikt is door ons ingesteld op 24
          maanden. Dit betekent dat na afloop van deze termijn de
          (persoons)gegevens niet meer kunnen worden uitgelezen.
        </p>
        <p>
          De cookies die voor het gebruik van Google Analytics worden geplaatst
          zijn:
        </p>
        <table>
          <thead>
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>slaapcoach.mline.nl</td>
              <td>
                Registreert een uniek ID die wordt gebruikt om statistische
                gegevens te genereren over hoe de bezoeker de website gebruikt.
              </td>
              <td>399 dagen</td>
              <td>HTTP Cookie</td>
            </tr>
            <tr>
              <td>_ga_#</td>
              <td>slaapcoach.mline.nl</td>
              <td>
                Verzamelt gegevens over het aantal keren dat een gebruiker de
                website heeft bezocht, evenals data voor het eerste en meest
                recente bezoek. Gebruikt door Google Analytics.
              </td>
              <td>399 dagen</td>
              <td>HTTP Cookie</td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>slaapcoach.mline.nl</td>
              <td>
                Gebruikt door Google Analytics om verzoeksnelheid te vertragen.
              </td>
              <td>1 dag</td>
              <td>HTTP Cookie</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>slaapcoach.mline.nl</td>
              <td>
                Registreert een uniek ID die wordt gebruikt om statistische
                gegevens te genereren over hoe de bezoeker de website gebruikt.
              </td>
              <td>1 dag</td>
              <td>HTTP Cookie</td>
            </tr>
          </tbody>
        </table>
        <p>
          Lees voor meer informatie het privacy beleid van Google en het
          specifieke privacy beleid van Google Analytics.
        </p>
        <h4>Wat zijn uw rechten?</h4>
        <p>
          <span className="cursive">Inzage en correctie</span>
          <br /> U kunt ons vragen om de gegevens die wij van u hebben in te
          zien. Neem dan contact met ons op en geef aan welke gegevens u wenst
          in te zien.
        </p>
        <p>
          U kunt ook verzoeken om een wijziging of correctie van uw gegevens.
          Neem dan contact met ons op en geef aan welke gegevens aangepast
          moeten worden. U kunt uw gegevens ook zelf aanpassen in uw account in
          de Slaap-app.{" "}
        </p>
        <p>
          <span className="cursive">Verwijdering</span>
          <br />U kunt ons vragen de gegevens die wij van u hebben te
          verwijderen. Neem dan contact met ons op en geef aan welke gegevens u
          wilt laten verwijderen.{" "}
        </p>
        <p>
          <span className="cursive">Beperking verwerking</span>
          <br />
          Onder omstandigheden kunt u ons verzoeken de verwerking van uw
          gegevens (tijdelijk) te beperken. Bijvoorbeeld als wij nog onjuiste
          persoonsgegevens van u gebruiken, wij onrechtmatig gebruik maken van
          uw gegevens, wij uw gegevens niet meer nodig hebben of vanwege
          persoonlijke omstandigheden. Neem in deze gevallen contact met ons op
          en geef aan welke verwerking u wenst te beperken.
        </p>
        <p>
          <span className="cursive">Gegevensoverdraagbaarheid</span>
          <br />U kunt aan ons vragen om de gegevens die wij van u hebben aan u
          of andere organisaties over te dragen. U kunt daarvoor contact met ons
          opnemen.
        </p>
        <p>
          <span className="cursive">Bezwaar</span>
          <br />U kunt altijd bezwaar maken tegen gebruik van uw gegevens door
          contact met ons op te nemen en toe te lichten waartegen u precies
          bezwaar maakt.
        </p>
        <p>
          <span className="cursive">Klachten</span>
          <br />
          Voor klachten over uw gegevens en de bescherming van uw privacy kunt u
          schriftelijk contact opnemen DBC via de onderstaande contactgegevens.
        </p>
        <p>
          Per mail: info@mline.nl
          <br />
          Per post: DBC International B.V., Linie 27, 5405 AR UDEN
        </p>
        <p>
          Ook heeft u het recht om een klacht in te dienen bij de{" "}
          <a href="https://autoriteitpersoonsgegevens.nl/nl/zelf-doen/gebruik-uw-privacyrechten/klacht-melden-bij-de-ap">
            Autoriteit Persoonsgegevens.
          </a>
        </p>
      </div>
      <div className="Tos__footer">
        <Button
          buttonStyle="secondary"
          copy="Weigeren"
          onClick={() => onClickedReject()}
        ></Button>
        <Button
          buttonStyle="orange"
          copy="Accepteren"
          onClick={() => {
            localStorage.terms.set(true);
            setAccepted(true);
            // onResolve();
          }}
        ></Button>
      </div>
    </div>
  );
}

function NotificationMod({ onResolve }) {
  const notificationRef = useRef();
  // if (!isOpen || isAccepted) return <></>;

  function onClickedReject() {
    //rejected
  }

  return (
    <div className="NotificationMod" ref={notificationRef}>
      <div className="NotificationMod__content">
        <Klaasje></Klaasje>
        <h2>Notificaties</h2>
        <p>
          De M line Slaapcoach ondersteunt notificaties. Zo kunnen we je nog
          beter helpen om jouw slaapperformance te verbeteren.{" "}
        </p>
        <b>
          Wil jij ook een betere slaapperformance door notificaties toe te
          staan?
        </b>
      </div>
      <div className="NotificationMod__footer">
        <Button
          buttonStyle="orange"
          copy="voorkeur opgeven"
          onClick={() => {
            onResolve();
          }}
        ></Button>
      </div>
    </div>
  );
}

const TosModal = create(Tos);
const NotificationModal = create(NotificationMod);

export { TosModal, NotificationModal };
