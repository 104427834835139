import "./Toggle.scss";
import { React, useState, useRef } from "react";
import { useEffect } from "react";

function Toggle(props) {
  const [toggle, setToggle] = useState(
    props.options[0].toLowerCase().replace(/\s/g, "-")
  );
  const toggleElements = [];
  const toggleOptionContainer = useRef();

  props.options.forEach((element, index) => {
    toggleElements.push(
      <div
        className={`toggle__options__selector`}
        key={"toggle_option_" + index}
        onClick={(e) => {
          switchToggle(e);
        }}
      >
        {element}
      </div>
    );
  });

  const switchToggle = (e) => {
    setToggle(e.target.innerText.toLowerCase().replace(/\s/g, "-"));
  };

  useEffect(() => {
    props.onChange(toggle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  useEffect(() => {
    var options = toggleOptionContainer.current.getElementsByClassName(
      "toggle__options__selector"
    );
    for (let item of options) {
      item.classList.remove("toggle__options__selector--active");
      item.classList.add("toggle__options__selector--inactive");
      if (toggle === item.innerText.toLowerCase().replace(/\s/g, "-")) {
        item.classList.remove("toggle__options__selector--inactive");
        item.classList.add("toggle__options__selector--active");
      }
    }
  });

  if (toggleElements.length > 1)
    return (
      <div className="toggle">
        <div className="toggle__options" ref={toggleOptionContainer}>
          {toggleElements}
        </div>
      </div>
    );
}

export default Toggle;
