import toast from "react-hot-toast";
import "./ItemContainer.scss";
import ActionItem from "../ActionItem/ActionItem";
import arrow from "../../assets/images/arrow.svg";
import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { gsap, Power2 } from "gsap/all";
import { Events } from "../../Logic/Events";
import { useGlobalContext } from "../../context/store";
import ContentBlock from "../ContentBlock/ContentBlock";

gsap.config({
  nullTargetWarn: false,
  trialWarn: false,
});

function ItemContainer(props) {
  const arrowRef = useRef();
  const tasksRef = useRef();
  const overlayRef = useRef();
  const itemContainer = useRef();
  const [state, dispatch] = useGlobalContext();
  let actionItems = [];
  let zIndex = 100;
  const [actionItemsUnfolded, setActionItemsUnfolded] = useState(false);
  const [allTasks, setAllTasks] = useState(props.tasks);
  const [showInformation, setShowInformation] = useState(false);
  const [refreshList, setRefreshList] = useState(0);

  function getItemVerticalPosition(index, duration = 0.2) {
    const currentTasks = tasksRef.current;
    if (
      currentTasks &&
      currentTasks.children.length > 0 &&
      currentTasks.children[index]
    ) {
      if (actionItemsUnfolded) {
        //if all items are visible,
        gsap.to(currentTasks.children, { duration, height: "auto" });
        gsap.to(
          [
            currentTasks.children[index].querySelector(".actionItem__content"),
            currentTasks.children[index],
          ],
          { duration, autoAlpha: 1 }
        );
        //return 0 so items go to their original position
        return 0;
      } else {
        //if only first item is visible
        for (let i = 1; i < currentTasks.children.length; i++) {
          //set height invisible tasks to 150 so they fit behind first task
          gsap.to(currentTasks.children[i], { duration, height: 150 });
          //hide text in invisible tasks
          gsap.to(
            currentTasks.children[i].querySelectorAll(".actionItem__content"),
            { duration, autoAlpha: 0 }
          );
        }
        if (index < 3) {
          //tween task element 150px
          return -150 * index;
        } else {
          //if there are more than 3 tasks, hide them,  so there won't be more than 3 tasks in folded view
          gsap.set(currentTasks.children[index], { autoAlpha: 0 });
          return -150 * index;
        }
      }
    }
  }

  function getTitleOfDomain(domain) {
    switch (domain) {
      case "food":
        return {
          title: "🍲 Eten, drinken & bewegen",
          information:
            "Om beter te kunnen slapen heb je meer nodig dan een M line matras.<br/><br/>Ook eten, drinken en bewegen hebben invloed op je nachtrust.",
        };

      case "mental":
        return {
          title: "🙏 Mentale rust",
          information:
            "Rust in je hoofd is zo ontzettend belangrijk voor een goede nachtrust!<br/><br/>Ik wil er graag voor zorgen dat je meer mentale rust gaat ervaren.",
        };

      case "bedroom":
        return {
          title: "🛌 Slaapkamer",
          information:
            "Goed slapen is in sommige slaapkamers niet zo makkelijk.<br/><br/>Laten we eens kijken of jouw slaapkamer al optimaal is ingericht.",
        };

      case "sleep":
        return {
          title: "😴 Slaapgedrag",
          information:
            "Dit onderdeel van deze Sleep Performance app gaat over hoe jij slaapt. En hoe dat beter kan.<br/><br/>Welke goede slaapgewoontes heb je jezelf al aangeleerd en wat zou je anders kunnen doen?",
        };

      case "nature":
        return {
          title: "🌳 Natuurgeluiden",
        };

      case "binaural":
        return {
          title: "🎧 Binaural audio",
        };

      case "noise":
        return {
          title: "📺 White noise",
        };

      case "visualisation":
        return {
          title: "👁️ Visualisatie",
        };

      case "bodyscan":
        return {
          title: "🧍‍♀️ Bodyscan",
        };

      default:
        return domain;
    }
  }

  function getItemScale(index) {
    if (actionItemsUnfolded) {
      //if items are visible, scale all to 1
      return 1;
    } else {
      //scale items smaller when items are folded
      if (index === 0) {
        return 1;
      } else if (index === 1) {
        return 0.98;
      } else {
        return 0.96;
      }
    }
  }

  function updateListLayout() {
    // hide arrow if there is only one task
    if (actionItems.length === 1) {
      gsap.set(arrowRef.current, { autoAlpha: 0 });
    } else {
      gsap.set(arrowRef.current, { autoAlpha: 1 });
    }
    const currentTasks = tasksRef.current;
    if (allTasks.length === 0) return;
    if (arrowRef.current) {
      const arrow = arrowRef.current;
      let tl = gsap.timeline({
        onComplete: function () {
          arrow.style.pointerEvents = "all";
        },
      });
      tl.add(function () {
        arrow.style.pointerEvents = "none";
      });
      tl.to(
        arrow,
        { duration: 0.2, rotation: actionItemsUnfolded ? 180 : 0 },
        0
      );
      tl.to(
        [currentTasks],
        {
          duration: actionItemsUnfolded ? 0 : 0.5,
          height:
            actionItemsUnfolded || props.tasks.length < 2
              ? "auto"
              : currentTasks.firstChild.clientHeight + 10,
        },
        0
      );
      tl.to(
        currentTasks.children,

        {
          duration: actionItemsUnfolded ? 0.5 : 0,
          y: (index) => getItemVerticalPosition(index, 0.2),
          scale: getItemScale,
          stagger: {
            each: 0,
          },
        },
        0
      );
    }
  }

  function setListLayout() {
    if (!arrowRef.current || !tasksRef.current.children) return;
    const currentTasks = tasksRef.current;
    let tl = gsap.timeline({
      onComplete: function () {
        if (arrowRef.current) arrowRef.current.style.pointerEvents = "all";
      },
    });
    tl.add(function () {
      if (arrowRef.current) arrowRef.current.style.pointerEvents = "none";
    });
    tl.set(arrowRef.current, { rotation: actionItemsUnfolded ? 180 : 0 }, 0);
    tl.set(
      [currentTasks],
      {
        height:
          actionItemsUnfolded || props.tasks.length < 2
            ? "auto"
            : currentTasks.firstChild.clientHeight + 10,
      },
      0
    );
    tl.set(
      currentTasks.children,
      {
        duration: 0,
        y: (index) => getItemVerticalPosition(index, 0),
        scale: getItemScale,
        stagger: {
          each: 0.1,
        },
      },
      0
    );
  }

  useEffect(() => {
    if (props.tasks.length < 1) return;
    updateListLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.tasks,
    actionItems.length,
    actionItemsUnfolded,
    allTasks.length,
    props.tasks,
  ]);

  useLayoutEffect(() => {
    setListLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (props.tasks.length < 1) return;
    setAllTasks(props.tasks);
    setListLayout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tasks]);

  const closedTask = (taskNo) => {
    if (actionItemsUnfolded) {
      // const newArr = allTasks;
      allTasks.splice(taskNo, 1);
      const tempTasks = [...allTasks];
      setAllTasks(tempTasks);
      if (tempTasks.length === 0) {
        gsap.to(itemContainer.current, {
          duration: 0.5,
          autoAlpha: 0,
          onComplete: function () {
            itemContainer.current.remove();
          },
        });
      }
    } else {
      //remove element when items folded
      if (allTasks.length > 1) {
        setAllTasks(function () {
          const newTasks = allTasks.slice(1);
          return newTasks;
        });
      } else {
        //remove container when last element is completed
        setAllTasks([]);
        gsap.to(itemContainer.current, {
          duration: 0.5,
          autoAlpha: 0,
          onComplete: function () {
            itemContainer.current.remove();
          },
        });
      }
    }
  };

  const openedTask = (taskNo) => {
    props.openModal(allTasks[taskNo]);
  };

  const getCurrentTime = () => {
    const currentDate = new Date();
    return currentDate;
  };

  const addTaskForUser = (task) => {
    Events.set(
      task._id,
      {
        timeLastEdit: getCurrentTime(),
        userAdded: true,
        isActive: true,
      },
      dispatch
    );
    window.dataLayer.push({ event_params: null });
    window.dataLayer.push({
      event: "interaction",
      event_params: {
        event_name: "start_task",
        task_name: task.content.title,
        task_id: task._id,
        task_domain: task.domain,
        task_goal_calmerNights: task.goal.includes("calmerNights"),
        task_goal_health: task.goal.includes("health"),
        task_goal_energy: task.goal.includes("energy"),
      },
    });
    props.setClosedTask(props.closedTasks + 1);
    toast.success("Actie toegevoegd");
  };

  useEffect(() => {
    gsap.fromTo(
      overlayRef.current,
      0.4,
      { transformOrigin: "top 50%", scaleY: 0, y: -20, ease: Power2.easeOut },
      { scaleY: 1, y: 0 }
    );
  }, [showInformation]);

  const completeTask = (type, task) => {
    switch (type) {
      case "completeIteration":
        props.closeModal(task);
        task.iterationCount = task.iterationCount | 0;
        window.dataLayer.push({ event_params: null });
        window.dataLayer.push({
          event: "interaction",
          event_params: {
            event_name: "complete_my_subtask",
            task_name: task.content.title,
            task_id: task._id,
            task_goal: task.goal,
            day: `${task.iterationCount} of ${task.duration} days`,
          },
        });
        Events.set(
          task._id,
          {
            timeLastEdit: getCurrentTime(),
            iterationCount: (task.iterationCount += 1),
            isActive: true,
          },
          dispatch
        );
        toast.success(`Vandaag voltooid!`);
        break;
      case "complete":
        props.closeModal(task, "complete");
        Events.set(
          task._id,
          {
            timeLastEdit: getCurrentTime(),
            iterationCount: (task.iterationCount += 1),
            isCompleted: true,
            isActive: false,
          },
          dispatch
        );
        props.setClosedTask(props.closedTasks + 1);
        toast.success(`Actie voltooid!`);
        break;
      default:
        break;
    }
  };

  allTasks.forEach(function (task, index) {
    if (task.type === "audio") {
      task.buttons = [...(tasksRef.buttons || []), "Afspelen"];
      task.clickFunction = props.openMediaPlayer;
    } else if (task.type === "audio" && task.isPlaying) {
      task.buttons = [...(tasksRef.buttons || []), "Pauzeren", "Stoppen"];
      task.clickFunction = addTaskForUser;
    } else if (task.subType === "fact" && props.overviewType === "allActions") {
      task.buttons = [...(tasksRef.buttons || []), "Openen", "Toevoegen"];
      task.clickFunction = addTaskForUser;
    } else if (task.subType === "fact" && props.overviewType === "myActions") {
      task.buttons = [...(tasksRef.buttons || []), "Openen", "Afronden"];
      task.clickFunction = completeTask;
    } else if (task.subType === "action") {
      task.buttons = [...(tasksRef.buttons || []), "Openen", "Voltooi dag"];
      task.clickFunction = completeTask;
    }

    actionItems.push(
      <ActionItem
        actionItemsUnfolded={actionItemsUnfolded}
        taskNo={index}
        className={`actionItem${index} ${
          actionItemsUnfolded ? "actionItem--visible" : ""
        }`}
        zIndex={zIndex}
        key={`item${index}_${refreshList}`}
        task={task}
        openedTask={openedTask}
        closedTask={closedTask}
        clickFunction={task.clickFunction}
        overviewType={props.overviewType}
        addTaskForUser={addTaskForUser}
        setRefreshList={setRefreshList}
        refreshList={refreshList}
        resetButtons={props.resetButtons}
      />
    );
    zIndex--;
  });
  return (
    <div
      className={`itemContainer itemContainer--${props.tasks[0].type}`}
      ref={itemContainer}
    >
      <div className="itemContainer__header">
        <div className="itemContainer__header__title">
          {getTitleOfDomain(props.title).title}{" "}
        </div>
        <div
          className="itemContainer__header__information"
          onClick={() => setShowInformation(!showInformation)}
        >
          {!showInformation && getTitleOfDomain(props.title).information && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.25 101.25">
              <circle className="svgCircle" cx="50.62" cy="50.62" r="50.63" />
              <path
                d="M42.61 26.48c0-2.28.64-3.98 1.91-5.1 1.27-1.12 3.32-1.67 6.15-1.67s4.89.57 6.19 1.71c1.3 1.14 1.95 2.83 1.95 5.06 0 4.46-2.71 6.69-8.13 6.69s-8.06-2.23-8.06-6.69Zm15.6 55.07H42.99v-44.1h15.22v44.09Z"
                className="svgWhite"
              />
            </svg>
          )}
          {showInformation && getTitleOfDomain(props.title).information && (
            <svg
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50.23 34.07"
              className="closingButton"
            >
              <defs></defs>
              <path
                className="svgCircleClosing"
                d="M25.12 34.07h25.11c-6.92 0-12.54 0-12.54-21.54h-.04c0-6.92-5.61-12.54-12.54-12.54S12.58 5.61 12.58 12.54h-.04C12.54 33.9 6.93 34.08 0 34.08h25.11V30.7v3.38Z"
              />
              <path
                className="svgCross"
                d="M18.756 17.4 29.977 6.177 31.47 7.67 20.248 18.89z"
              />
              <path
                className="svgCross"
                d="m18.756 7.67 1.492-1.492 11.221 11.221-1.492 1.492z"
              />
            </svg>
          )}
        </div>

        <img
          className="itemContainer__header__arrow"
          ref={arrowRef}
          src={arrow}
          alt="itemArrow"
          onClick={() =>
            setActionItemsUnfolded(actionItemsUnfolded === false ? true : false)
          }
        ></img>
        {showInformation && (
          <div className="itemContainer__header__overlay" ref={overlayRef}>
            {" "}
            <ContentBlock
              isSpecial
              copy={[getTitleOfDomain(props.title).information]}
              noMargin={true}
            />
          </div>
        )}
      </div>
      {refreshList > -1 && (
        <div className="itemContainer__tasks" ref={tasksRef}>
          {actionItems}
        </div>
      )}
    </div>
  );
}

export default ItemContainer;
