import React, { useState } from "react";
import "./Reset.scss";
import { sendPasswordReset } from "../../firebase-config";
import { setErrorMessage } from "../../context/user/actions";
import { useGlobalContext } from "../../context/store";
import ContentBlock from "../ContentBlock/ContentBlock";
import { useNavigate } from "react-router-dom";

function Reset() {
  const [state, dispatch] = useGlobalContext();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  return (
    <div className="Reset">
      <div className="Reset__container">
        <ContentBlock
          title="Je wachtwoord resetten"
          copy="Ben je je wachtwoord vergeten? Geen probleem! Vul hieronder je e-mailadres in. We sturen je dan een link waarmee je je wachtwoord kunt resetten."
        />
        <input
          type="text"
          className="Reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Je e-mailadres"
        />
        {state.user.error && (
          <div className="Form__errorField">{state.user.error}</div>
        )}
        <button
          className="Reset__btn"
          onClick={() => {
            if (!email) {
              dispatch(
                setErrorMessage("Het email veld is niet (juist) ingevuld.")
              );
              return;
            }
            sendPasswordReset(email).then(navigate("/home"));
          }}
        >
          Reset
        </button>
      </div>
    </div>
  );
}

export default Reset;
