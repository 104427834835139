import { setUserProfile } from "../context/user/actions";
import { getUser } from "../firebase-config";

let allEvents = [];

// Get all tasks from the DB
async function _getEventListFromDB() {
  const currentUser = await getUser();
  return currentUser.events ? currentUser.events : {};
}

// Refresh the task list
async function refresh() {
  allEvents = await _getEventListFromDB();
  return true;
}

// Get all the tasks, either locally or from the DB
async function getAllEvents() {
  if (!allEvents || Object.keys(allEvents).length < 1)
    allEvents = await _getEventListFromDB();
  return allEvents;
}

async function setEvents(task, event, dispatch) {
  // var userRef = db.ref("users/");
  let currentTask = allEvents[task];
  currentTask = { ...currentTask, ...event };
  let taskList = allEvents;
  taskList[task] = currentTask;
  await dispatch(setUserProfile({ events: taskList }));
}

export const Events = {
  refresh,
  get: getAllEvents,
  set: setEvents,
};
