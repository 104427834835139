// All Regexes
const regexList = {
  name: {
    regex: /\{\{name(?:.(first|last))?\}\}/gmi,
    replacer: replaceName,
  }
}

function capitalize(input = '', type) {

  switch(type) {
    case 'sentence':
      return capitalizeFirstLetter(input);

    case 'word':
      return capitalizeWords(input);

    case 'all':
      return input.toUpperCase();
    
    case 'none':
      return input.toLowerCase();

    default:
      return input;
  }
  
  function capitalizeFirstLetter(input) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  function capitalizeWords(input) {
    const words = input.split(" ");

    return words.map((word) => { 
        return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");
  }


}


function replaceName(fullMatch, subMatch, state) {
  const fullName = state?.user?.profile?.name;
  if (!fullName) return '';
  const [firstName, ...lastName] = fullName.split(' ');

  switch (subMatch) {
    case 'first':
      return firstName;
    case 'last':
      return lastName.join(' ');
    default:
      return fullName;
  }
}

export function findAndReplace(input, state, options) {
  var output = input;

  Object.entries(regexList).forEach((regex) => {
    output = output.replaceAll(regex[1].regex, (match, group1) => regex[1].replacer.call(this, match, group1, state));
  });

  if (options?.capitalize) output = capitalize(output, options.capitalize);

  return output;

}
