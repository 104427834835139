// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
  signOut,
  deleteUser,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  updateDoc,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { isRateLimited } from "./Logic/RateLimiter";
import { isDev } from "./helpers/devDetection";

// import firebase from "firebase/compat/app";
// var firebase = require("firebase");
// var firebaseui = require("firebaseui");

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//development old
const oldDevelopmentConfig = {
  apiKey: "AIzaSyC3xUo8cwOkmTkD6LKYRs40QO4YvwTvW5E",
  authDomain: "m-line-sleep-performance-coach.firebaseapp.com",
  projectId: "m-line-sleep-performance-coach",
  storageBucket: "m-line-sleep-performance-coach.appspot.com",
  messagingSenderId: "1024248219323",
  appId: "1:1024248219323:web:205f9d4b8d4b29acea8b9e",
  measurementId: "G-RLQCTLCF6B",
};

// development new
const newDevelopmentConfig = {
  apiKey: "AIzaSyBIg0QStE1AlGpQjg5VMqrTTzeLRmBKLmA",
  authDomain: "mline-sleepcoach-development.firebaseapp.com",
  projectId: "mline-sleepcoach-development",
  storageBucket: "m-line-sleep-performance-coach.appspot.com", // Using the old storage bucket to prevent having to copy all audio files to the new environment
  messagingSenderId: "927934330698",
  appId: "1:927934330698:web:d6ea6866789486269873b3",
};

//live
const liveConfig = {
  apiKey: "AIzaSyCD7hscnsPZHOAjQ-HmkgWFT1MgS1emolU",
  authDomain: "slaapcoach-m-line.firebaseapp.com",
  projectId: "slaapcoach-m-line",
  storageBucket: "slaapcoach-m-line.appspot.com",
  messagingSenderId: "973020214848",
  appId: "1:973020214848:web:3fdffe2027437a3eaed1a5",
  measurementId: "G-E2VW17N1GQ",
};

const app = initializeApp(isDev() ? newDevelopmentConfig : liveConfig);
console.log(app);
let auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

let currentUser = "";

const getDBresults = async (query) => {
  try {
    await isRateLimited();

    const docsSnap = await getDocs(query);
    let results = [];
    docsSnap.forEach((doc) => {
      results.push(doc.data());
    });
    return results;
  } catch (e) {
    return e;
  }
};

const getUser = async (id, events) => {
  if (events) {
    currentUser.events = events;
  }

  if (currentUser) return currentUser;
  if (!auth) return null;
  try {
    await isRateLimited();
    if (!auth?.currentUser?.uid) return;
    const currentId = id ? id : auth.currentUser.uid;
    const q = query(collection(db, "users"), where("uid", "==", currentId));

    const [user] = await getDBresults(q);
    currentUser = user;
    return user;
  } catch (e) {
    console.error(e);
    return e;
  }
};

const getQuestions = async () => {
  const q = query(collection(db, "questions"));

  return getDBresults(q);
};

const saveIntake = async (intakeData) => {
  try {
    await isRateLimited();
    const q = query(
      collection(db, "users"),
      where("uid", "==", auth.currentUser.uid)
    );
    const docs = await getDocs(q);
    if (docs.docs.length < 1 || docs.docs.length > 1)
      return console.error(
        "Something went wrong while saving the intake information."
      );

    return updateDoc(docs.docs[0].ref, { intakeData });
  } catch (e) {
    return e;
  }
};

const saveUserProfile = async (payload) => {
  try {
    await isRateLimited();
    const q = query(
      collection(db, "users"),
      where("uid", "==", auth.currentUser.uid)
    );

    const docs = await getDocs(q);
    if (docs.docs.length < 1 || docs.docs.length > 1)
      return console.error(
        "Something went wrong while saving the profile data"
      );

    const user = docs.docs[0].data();
    // Prevent the user from writing other data to the user. only this data is allowed to be written
    await updateDoc(docs.docs[0].ref, {
      intakeData: payload.intakeData || user.intakeData || {},
      settings: payload.settings || user.settings || {},
      name: payload.name || user.name,
      events: payload.events || user.events || {},
    });
    // if (currentUser) currentUser.profile = payload;
    if (!payload.intakeData) return getUser("", payload.events || user.events);

    currentUser = payload;
    return currentUser;
  } catch (e) {
    return e;
  }
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        isAdmin: false,
      });

      return {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        isAdmin: false,
      };
    } else {
      return docs.docs[0].data();
    }
  } catch (err) {
    console.error(err);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    const user = res.user;
    return getUser(user.uid);
  } catch (err) {
    throw Error(err.code);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  const res = await createUserWithEmailAndPassword(auth, email, password);
  window.dataLayer.push({ event_params: null });
  window.dataLayer.push({
    event: "interaction",

    event_params: {
      event_name: "account_created",
    },
  });
  const user = res.user;
  await setDoc(doc(db, "users", user.uid), {
    uid: user.uid,
    name,
    authProvider: "local",
    email,
    isAdmin: false,
  });
  return {
    uid: user.uid,
    name,
    authProvider: "local",
    email,
    isAdmin: false,
  };
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
  }
};

const logout = () => {
  currentUser = "";
  signOut(auth);
};

const deleteUserFromApp = async () => {
  const user = await getUser();
  if (!user) return console.error("Did not find user");
  const userDocRef = doc(db, "users", user.uid);
  await deleteDoc(userDocRef).catch((e) => {
    console.error("Panic while deleting user data: ", e);
  });
  await deleteUser(auth.currentUser).catch((e) => {
    console.error("Panic while deleting user authentication data: ", e);
  });
  return true;
};

export {
  auth,
  db,
  getDBresults,
  getQuestions,
  getUser,
  signInWithEmailAndPassword,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  saveIntake,
  saveUserProfile,
  deleteUserFromApp,
};
