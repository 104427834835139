import Button from "../Button/Button";
import ContentBlock from "../ContentBlock/ContentBlock";
import "./ActionItem.scss";
import { useRef, useState } from "react";

const nextDayOrLater = (first, second) => {
  if (
    (first.getFullYear() <= second.getFullYear() &&
      first.getMonth() <= second.getMonth() &&
      first.getDate() < second.getDate()) ||
    first.getFullYear() < second.getFullYear() ||
    (first.getFullYear() <= second.getFullYear() &&
      first.getMonth() < second.getMonth())
  ) {
    return true;
  } else {
    return false;
  }
};

const getIntervalInfo = (interval) => {
  switch (interval) {
    case "daily":
      return { copy: "dag", timeChecker: nextDayOrLater };
    case "weekly":
      return { copy: "week", timeChecker: nextDayOrLater };
    case "monthly":
      return { copy: "maand", timeChecker: nextDayOrLater };
    default:
      return {};
  }
};

function ActionItem(props) {
  const actionItem = new useRef();
  let buttons = [];
  const [itemRefresh, setItemRefresh] = useState(0);
  if (props.task.buttons && props.task.buttons.length > 0) {
    props.task.buttons.forEach(function (element, index) {
      let disabled = false;
      let clicker = () => {};
      let buttonType = "secondary";

      if (props.task.type === "task" && element.toLowerCase() === "openen") {
        clicker = () => {
          props.openedTask(props.taskNo);
        };
      } else if (
        props.task.type === "task" &&
        element.toLowerCase() === "afronden"
      ) {
        buttonType = "orange";
        clicker = () => {
          window.dataLayer.push({ event_params: null });
          window.dataLayer.push({
            event: "interaction",
            event_params: {
              event_name: "complete_my_task",
              task_name: props.task.content.title,
              task_id: props.task._id,
              task_domain: props.task.domain,
              task_goal_calmerNights: props.task.goal.includes("calmerNights"),
              task_goal_health: props.task.goal.includes("health"),
              task_goal_energy: props.task.goal.includes("energy"),
            },
          });
          props.clickFunction("complete", props.task);
        };
      } else if (
        props.task.type === "task" &&
        element.toLowerCase() === "toevoegen"
      ) {
        buttonType = "orange";
        clicker = () => {
          window.dataLayer.push({ event_params: null });
          window.dataLayer.push({
            event: "interaction",
            event_params: {
              event_name: "start_task",
              task_name: props.task.content.title,
              task_id: props.task._id,
              task_domain: props.task.domain,
              task_goal_calmerNights: props.task.goal.includes("calmerNights"),
              task_goal_health: props.task.goal.includes("health"),
              task_goal_energy: props.task.goal.includes("energy"),
            },
          });
          props.clickFunction(props.task);
        };
      } else if (
        props.task.type === "task" &&
        element.toLowerCase() === "voltooi dag" &&
        props.overviewType === "myActions"
      ) {
        buttonType = "orange";
        const iterationCount = props.task.iterationCount | 0;
        clicker = () => {
          window.dataLayer.push({ event_params: null });
          window.dataLayer.push({
            event: "interaction",
            event_params: {
              event_name: "complete_subtask",
              task_name: props.task.content.title,
              task_id: props.task._id,
              task_goal: props.task.goal,
              day: `${props.task.iterationCount + 1} of ${
                props.task.duration
              } days`,
            },
          });
          props.clickFunction(
            iterationCount < props.task.duration - 1
              ? "completeIteration"
              : "complete",
            props.task
          );
        };
        let lastEdit;
        if (
          props.task.timeLastEdit &&
          props.task.timeLastEdit.toDate &&
          props.task.iterationCount > 0
        ) {
          lastEdit = props.task.timeLastEdit
            ? props.task.timeLastEdit.toDate()
            : undefined;
        } else if (props.task.timeLastEdit && props.task.iterationCount > 0) {
          lastEdit = new Date(props.task.timeLastEdit);
        }
        const intervalInfo = getIntervalInfo(props.task.interval);
        if (lastEdit) {
          const currentDate = new Date();
          if (!intervalInfo.timeChecker(lastEdit, currentDate)) {
            disabled = true;
          }
        }
      } else if (
        props.task.type === "task" &&
        element.toLowerCase() === "voltooi dag" &&
        props.overviewType === "allActions"
      ) {
        element = "Beginnen";
        buttonType = "orange";
        disabled = false;
        clicker = () => {
          props.addTaskForUser(props.task);
        };
      } else if (
        props.task.type === "audio" &&
        props.task.subType === "meditation"
      ) {
        buttonType = "orange";
        clicker = (e) => {
          props.clickFunction(props.task.content.title, props.task.audioPath, {
            useTimer: false,
            loop: false,
            allowSeeking: true,
            allowJumping: true,
          });
        };
      } else if (
        props.task.type === "audio" &&
        props.task.subType === "sleepSounds"
      ) {
        buttonType = "orange";
        clicker = () => {
          props.task.isPlaying = !props.task.isPlaying;
          props.clickFunction(props.task.content.title, props.task.audioPath, {
            useTimer: true,
            loop: true,
            allowSeeking: false,
            allowJumping: false,
          });
        };
      }
      buttons.push(
        <Button
          className={`button${index} ${
            element.toLowerCase() === "afronden" ||
            element.toLowerCase() === "voltooien"
              ? "closeButton"
              : "actionButton"
          }`}
          key={`button${index}`}
          copy={element}
          onClick={function (setButtonStyle) {
            if (props.task.type === "audio") {
              setItemRefresh(itemRefresh + 1);
              setButtonStyle("orange");
              props.resetButtons();
              setButtonStyle("grey" + itemRefresh);
            }

            clicker();
          }}
          buttonStyle={buttonType}
          disabled={disabled}
        />
      );
    });
  }

  return (
    <div
      className={`actionItem ${props.className}`}
      ref={actionItem}
      style={{ zIndex: props.zIndex }}
    >
      <div className="actionItem__content">
        <ContentBlock
          title={props.task.content.title}
          copy={props.task.content.excerpt}
          type={props.task.subType}
          task={props.task}
        />
        {buttons.length > 0 && itemRefresh > -1 && (
          <div className="actionItem__content__buttons">{buttons}</div>
        )}
      </div>
    </div>
  );
}

export default ActionItem;
