import { collection, query } from "firebase/firestore";
import { db } from "../firebase-config";
import { getResults } from "./Helpers";

let allTasks = [];

// Add custom functions to the tasks array for easy handling
function returnTasks(tasks = []) {
  Object.defineProperties(tasks, {
    filter: {
      value: (filters = {}) => {
        return returnTasks(filterTasks(tasks, filters));
      },
      configurable: true,
    },
    meets: {
      value: (conditions, returnEmptyConditions = true) => {
        return returnTasks(
          meetsConditions(tasks, conditions, returnEmptyConditions)
        );
      },
      configurable: true,
    },
    sort: {
      value: (order = "ASC") => {
        return returnTasks(sortTasks(tasks, order));
      },
      configurable: true,
    },
  });

  return tasks;
}

// Get all tasks from the DB
function _getTaskListFromDB() {
  const allTaskQuery = query(collection(db, "tasks"));
  return getResults(allTaskQuery);
}

// Refresh the task list
async function refresh() {
  allTasks = await _getTaskListFromDB();
  return true;
}

// Logic for testing if conditions are met
function testCondition(condition, activeConditions) {
  if (!condition.operator || condition.operator === "")
    return activeConditions[condition.property];
  if (condition.operator === "!") return !activeConditions[condition.property];
  switch (condition.property) {
    case "timeOfDay":
      let [hours, minutes] = condition.value.split(":");
      hours = Number(hours);
      minutes = Number(minutes);
      const setDate = new Date(activeConditions.timeOfDay);
      setDate.setHours(hours);
      setDate.setMinutes(minutes);
      if (condition.operator === "<")
        return activeConditions.timeOfDay.getTime() <= setDate.getTime();
      if (condition.operator === ">")
        return activeConditions.timeOfDay.getTime() >= setDate.getTime();
      if (condition.operator === "==")
        return activeConditions.timeOfDay.getTime() === setDate.getTime();
      break;
    default:
      console.warn(`${condition.property} is not recognised`);
      break;
  }
}

// Filter the provided list of tasks based on a set of conditions
function meetsConditions(tasks, conditions = {}, returnEmptyConditions = true) {
  const defaultConditions = {
    timeOfDay: new Date(),
  };

  const activeConditions = { ...defaultConditions, ...conditions };

  const conditionedTasks = [...tasks].filter((task) => {
    const taskConditions = task.conditions || false;

    if (!taskConditions || !Array.isArray(taskConditions))
      return returnEmptyConditions;

    let isValid = true;

    taskConditions.forEach((condition) => {
      if (!testCondition(condition, activeConditions)) {
        isValid = false;
        return false;
      }
    });
    return isValid;
  });

  return conditionedTasks;
}

// Sort the provided tasks by their importance property
function sortTasks(tasks, method = "ASC") {
  return [...tasks].sort((a, b) => {
    return method.toUpperCase() === "ASC"
      ? a.importance - b.importance
      : b.importance - a.importance;
  });
}

// Get filtered tasks
function filterTasks(tasks, filters) {
  const filteredTasks = [...tasks].filter((task) => {
    let isValid = true;
    for (let key in filters) {
      if (Object.hasOwnProperty.call(task, key)) {
        if (typeof filters[key] === "function")
          isValid = filters[key](task[key]) ? isValid : false;
        else if (filters[key] !== task[key]) isValid = false;
      }
    }
    return isValid;
  });

  return filteredTasks;
}

// Get all the tasks, either locally or from the DB
async function getAllTasks(sort = false, method = "ASC") {
  if (!allTasks || allTasks.length < 1) allTasks = await _getTaskListFromDB();
  return returnTasks(sort ? sortTasks(allTasks, method) : allTasks);
}

function setTasks(tasks) {
  allTasks = tasks;
  return returnTasks(allTasks);
}

export const Tasks = {
  refresh,
  get: getAllTasks,
  set: setTasks,
};
