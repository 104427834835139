import "./Carret.scss";
import React from "react";
function Carret(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49.367"
      height="16.367"
      viewBox="0 0 49.367 16.367"
    >
      <g id="qrArrow" transform="translate(-399.317 -387.327)">
        <line
          id="Line_1"
          data-name="Line 1"
          x2="22"
          y2="11"
          transform="translate(402 390.01)"
          fill="none"
          stroke="#707070"
          stroke-linecap="round"
          stroke-width="4"
        />
        <line
          id="Line_2"
          data-name="Line 2"
          x1="22"
          y2="11"
          transform="translate(424 390.01)"
          fill="none"
          stroke="#707070"
          stroke-linecap="round"
          stroke-width="4"
        />
      </g>
    </svg>
  );
}

export default Carret;
