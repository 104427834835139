import gsap, { Draggable } from 'gsap/all';
import InertiaPlugin from 'gsap/InertiaPlugin';
import { useEffect, useRef, useState } from 'react';
import { useLayoutEffect } from 'react';
import './TimePicker.scss';

const defaultValue = 10;

function TimePicker(props) {
  const [minuteCopy, setMinuteCopy] = useState('minuut');

  const boundsRef = useRef();
  const minuteDraggableRef = useRef();
  
  function createMinutes() {
    let minuteArray = [];
    
    for (let i = 1; i < 61; i++) {
      minuteArray.push((
        <div className="TimePicker__content__scrollItem" key={"TimePicker__scrollItem__minutes__" + i}>{i}</div>
      ));
    }
    return minuteArray;
  }

  function checkCopy(val) {
    setMinuteCopy((Math.abs(val) > 1) ? 'minuten' : 'minuut');
  }

  function getEndPosition(endValue) {
    const singleHeight = minuteDraggableRef.current.clientHeight / 60;
    const itemIndex = Math.round(endValue / singleHeight);
    const snapValue = itemIndex * singleHeight;

    const newValue = Math.max(Math.min(-itemIndex + 1, 60), 1);
    checkCopy(newValue);

    if (typeof props.onChange === 'function') props.onChange(newValue);

    return snapValue;
  }

  function onPickerClicked() {
    if (typeof props.onClick === 'function') props.onClick();
  }
  
  function createDraggable() {
    gsap.registerPlugin(Draggable, InertiaPlugin);
    Draggable.create(
      minuteDraggableRef.current,
      {
        type: 'y',
        edgeResistance: 0.6,
        bounds: boundsRef.current,
        inertia: true,
        snap: {
          y: (endValue) => getEndPosition(endValue),
        },
        onClick: () => {
          onPickerClicked();
        },
      }
    )
  }

  function setDomCurrentMinutes(minutes) {
    const singleHeight = minuteDraggableRef.current.clientHeight / 60;
    const validatedMinutes = Math.max(Math.min(minutes, 60), 1);
    const newOffset = -singleHeight * (validatedMinutes - 1);
    gsap.set(minuteDraggableRef.current, {y: newOffset});
    checkCopy(minutes);
  }

  useEffect(() => {
    setDomCurrentMinutes(props.value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])
    
  useLayoutEffect(() => {
    createDraggable();
    setDomCurrentMinutes(props.value || defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  window.timetest = {
    setDomCurrentMinutes,
  }

  return (
    <div className="TimePicker" ref={props.forwardRef}>
      <div className="TimePicker__overlay"></div>
      <div className="TimePicker__content">
        <div className="TimePicker__content__column" ref={boundsRef}>
          <div className="TimePicker__content__scrollList" id="TimePicker__content__scrollList__minutes" ref={minuteDraggableRef}>
            {createMinutes()}
          </div>
          <span onClick={onPickerClicked}>&nbsp; {minuteCopy}</span>
        </div>
      </div>
    </div>
  );
}

export default TimePicker;
