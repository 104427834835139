import { ReactComponent as LogoSvg } from "../../assets/images/logo-subline.svg";
import "./LogoSubline.scss";

function LogoSubline(props) {
  return (
    <div className={`logo-subline logo-subline--${props.colorMode}`}>
      <LogoSvg />
    </div>
  );
}

export default LogoSubline;
