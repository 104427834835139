import { useEffect, useLayoutEffect, useRef } from "react";
import * as QRious from "qrious";
import Klaasje from "../../Components/Klaasje/Klaasje";
import Logo from "../../Components/Logo/Logo";
import Carret from "../../Components/Carret/Carret";
import "./Desktop.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { useGlobalContext } from "../../context/store";
import { gsap, Power2 } from "gsap/all";

export default function Desktop({ isMobileViewPort }) {
  const [state] = useGlobalContext();
  const qrCanvasRef = useRef();
  const qr = useRef();
  const qrImage = useRef();
  const conversationFlow = useRef();

  useLayoutEffect(() => {
    if (!qr.current)
      qr.current = new QRious(
        {
          element: qrCanvasRef.current,
          value: window.location.origin,
          backgroundAlpha: 0,
          foreground: "#00202f",
          size: 150,
        },
        [isMobileViewPort, qrCanvasRef.current]
      );

    qrImage.current = qr.current.image.src;
  });

  useEffect(() => {
    if (!isMobileViewPort) qrCanvasRef.current.src = qrImage.current;
  }, [isMobileViewPort]);

  useLayoutEffect(() => {
    if (state.user.loading === false) {
      gsap.context(() => {
        gsap.fromTo(
          ".chatBubble",
          {
            transformOrigin: "bottom left",
            scaleY: 0,
          },
          {
            transformOrigin: "bottom left",
            scaleY: 1,
            stagger: 0.8,
            duration: 0.4,
            ease: Power2.easeOut,
          }
        );
      }, conversationFlow);
    }
  }, [state.user.loading]);

  if (isMobileViewPort)
    return (
      <div className="Mobile --landscape">
        <div className="Mobile__message">
          <div className="Mobile__message__container">
            <div className="Mobile__message__title">Draai je scherm</div>
            <div className="Mobile__message__copy">
              Deze app is gemaakt voor mobiele apparaten in portretmodus. Draai
              je scherm om verder te gaan.
            </div>
          </div>
          <FontAwesomeIcon icon={faRotate} />
        </div>
      </div>
    );

  return (
    <div className="Desktop">
      {/* <div className="Desktop__message">
        <div className="Desktop__message__klaasje">
          <Klaasje />
        </div>
        <div className="Desktop__message__chatbubble">
          <div className="Desktop__message__chatbubble__copy__container">
            <div className="Desktop__message__chatbubble__copy__title">
              Mobiele applicatie
            </div>
            <div className="Desktop__message__copy__text">
              De M line slaapcoach is gemaakt voor mobiele apparaten. Scan deze
              QR code om direct doorgestuurd te worden naar de website op je
              telefoon.
            </div>
          </div>
          <img
            alt="qr-code"
            className="Desktop__message__qr"
            ref={qrCanvasRef}
          ></img>
        </div>
      </div> */}
      <div className="Desktop__container">
        <div className="Desktop__row Desktop__row--top">
          <div className="Desktop__row__collumn">
            <div className="Desktop__logo">
              <Logo colorMode={state.theme.theme}></Logo>
            </div>
            <h2 className="Desktop__title">
              Verbeter jouw nachtrust
              <br />
              met de Slaapcoach van M line
            </h2>
            <p className="Desktop__text">
              Open deze pagina op je mobiel, of scan de QR-code
              <br />
              met je mobiel om de M line Slaapcoach te openen.
            </p>
            <div className="Desktop__carret">
              <Carret></Carret>
            </div>
            <div className="Desktop__qrHolder">
              <img
                alt="qr-code"
                className="Desktop__qrHolder__qr"
                ref={qrCanvasRef}
              ></img>
            </div>
            <p className="Desktop__text Desktop__text--light">
              Heb je nog vragen?
              <br />
              Bekijk hieronder de{" "}
              <span className="Desktop__text-- Desktop__text--bold">
                veelgestelde vragen
              </span>
            </p>
            <div className="Desktop__carret">
              <Carret></Carret>
            </div>
          </div>
          <div className="Desktop__row__collumn Desktop__row__collumn--white">
            <div className="Desktop__conversation">
              <div className="Desktop__conversation__header">
                <Klaasje></Klaasje>
                <div className="Desktop__conversation__headerText">
                  <h2 className="Desktop__conversation__title">
                    Chat met Klaasje
                  </h2>
                  <p className="Desktop__conversation__text">
                    Jouw persoonlijke Slaapcoach!
                  </p>
                </div>
              </div>
              <div className="conversationFlow" ref={conversationFlow}>
                <div className="bubbleGroup">
                  <div className="chatBubble" key={"chatBubble_0"}>
                    <span className="text">Hallo ik ben Klaasje.</span>
                  </div>
                  <div className="chatBubble" key={"chatBubble_1"}>
                    <span className="text">
                      Kan jij wel een betere nachtrust gebruiken?
                    </span>
                  </div>
                </div>
                <div className="bubbleGroup bubbleGroup--answer">
                  <div className="chatBubble" key={"chatBubble_2"}>
                    <span className="text">Daar zeg ik geen nee tegen!</span>
                  </div>
                </div>
                <div className="bubbleGroup">
                  <div className="chatBubble" key={"chatBubble_0"}>
                    <span className="text">Super!</span>
                  </div>
                  <div className="chatBubble" key={"chatBubble_1"}>
                    <span className="text">
                      Met de Slaapcoach app van M line zorg je ervoor dat je een
                      betere nachtrust krijgt.
                    </span>
                  </div>
                </div>
                <div className="bubbleGroup bubbleGroup--answer">
                  <div className="chatBubble" key={"chatBubble_2"}>
                    <span className="text">
                      En waar kan ik deze app vinden?
                    </span>
                  </div>
                </div>
                <div className="bubbleGroup">
                  <div className="chatBubble" key={"chatBubble_0"}>
                    <span className="text">
                      Je kan de QR-code hier links scannen, of deze pagina op je
                      mobiele telefoon openen.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Desktop__row ">
          <h2 className="Desktop__questions__title">Veelgestelde vragen</h2>
        </div>
        <div className="Desktop__row Desktop__row--bottom">
          <div className="Desktop__row__collumn">
            <div className="Desktop__questions">
              <div className="Desktop__questions__container">
                <div className="Desktop__questions__item">
                  <div className="Desktop__questions__item__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="26"
                      viewBox="0 0 20 26"
                    >
                      <path
                        id="Icon_material-insert-drive-file"
                        data-name="Icon material-insert-drive-file"
                        d="M8.5,3A2.549,2.549,0,0,0,6.013,5.6L6,26.4A2.549,2.549,0,0,0,8.488,29H23.5A2.559,2.559,0,0,0,26,26.4V10.8L18.5,3Zm8.75,9.1V4.95l6.875,7.15Z"
                        transform="translate(-6 -3)"
                        fill="#003148"
                      />
                    </svg>
                  </div>
                  <div className="Desktop__questions__item__text">
                    <div className="Desktop__questions__item__title">
                      Klaasje is een digitale Slaapcoach, kan ik ook persoonlijk
                      advies krijgen?
                    </div>
                    <div className="Desktop__questions__item__answer">
                      Voor persoonlijk advies kun je het beste contact opnemen
                      met de klantenservice. Voor algemene oefeningen en hulp
                      bij een betere nachtrust staat Klaasje altijd voor je
                      klaar.
                    </div>
                  </div>
                </div>
                <div className="Desktop__questions__item">
                  <div className="Desktop__questions__item__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="27"
                      viewBox="0 0 20 27"
                    >
                      <path
                        id="Icon_material-keyboard-voice"
                        data-name="Icon material-keyboard-voice"
                        d="M17.5,21.553a4.257,4.257,0,0,0,4.271-4.263l.014-8.526a4.286,4.286,0,0,0-8.571,0v8.526A4.269,4.269,0,0,0,17.5,21.553Zm7.571-4.263A7.4,7.4,0,0,1,17.5,24.537a7.4,7.4,0,0,1-7.571-7.247H7.5a9.932,9.932,0,0,0,8.571,9.549V31.5h2.857V26.839A9.92,9.92,0,0,0,27.5,17.289Z"
                        transform="translate(-7.5 -4.5)"
                        fill="#003148"
                      />
                    </svg>
                  </div>
                  <div className="Desktop__questions__item__text">
                    <div className="Desktop__questions__item__title">
                      Gebruikt de Slaapcoach mijn persoonlijke data om een
                      profiel te creëren?
                    </div>
                    <div className="Desktop__questions__item__answer">
                      Ja, maar we slaan alleen op wat we nodig hebben om jou
                      optimaal te kunnen helpen met het verbeteren van jouw
                      slaap. Dit houdt in: je naam en e-mail, je slaapgedrag:
                      (zelf ingegeven, we verzamelen geen data tijdens je slaap)
                      en je interacties met de feitjes en taken in de app.
                    </div>
                  </div>
                </div>
                <div className="Desktop__questions__item">
                  <div className="Desktop__questions__item__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        id="Icon_metro-user-check"
                        data-name="Icon metro-user-check"
                        d="M25.071,16.178l-6.75,6.75-2.25-2.25-1.5,1.5,3.75,3.75,8.25-8.25Zm-12,3.75h7.5v-2.7a14.4,14.4,0,0,0-6-1.74V14.252a6.7,6.7,0,0,0,3-5.574c0-3.728,0-6.75-4.5-6.75s-4.5,3.022-4.5,6.75a6.7,6.7,0,0,0,3,5.574v1.237c-5.088.416-9,2.916-9,5.939h10.5v-1.5Z"
                        transform="translate(-2.571 -1.928)"
                        fill="#003148"
                      />
                    </svg>
                  </div>
                  <div className="Desktop__questions__item__text">
                    <div className="Desktop__questions__item__title">
                      Wat gebeurt er met mijn gegevens als ik mijn account
                      verwijder?
                    </div>
                    <div className="Desktop__questions__item__answer">
                      Alle data die we van je hebben opgeslagen wordt volledig
                      verwijderd als je je account deactiveert.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Desktop__row__collumn">
            <div className="Desktop__questions">
              <div className="Desktop__questions__container">
                <div className="Desktop__questions__item">
                  <div className="Desktop__questions__item__icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="40"
                      height="40"
                      viewBox="0 0 30 30"
                    >
                      <g id="layer1">
                        <path
                          d="M 9 3 C 7.9092972 3 7 3.9093 7 5 L 7 25 C 7 26.0907 7.9092972 27 9 27 L 21 27 C 22.090703 27 23 26.0907 23 25 L 23 5 C 23 3.9093 22.090703 3 21 3 L 9 3 z M 9 5 L 21 5 L 21 21 L 9 21 L 9 5 z M 15 22 A 2 2 0 0 1 17 24 A 2 2 0 0 1 15 26 A 2 2 0 0 1 13 24 A 2 2 0 0 1 15 22 z "
                          id="rect1391"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="Desktop__questions__item__text">
                    <div className="Desktop__questions__item__title">
                      Hoe installeer ik deze webapp op mijn telefoon?
                    </div>
                    <div className="Desktop__questions__item__answer">
                      Je kan de app gemakkelijk op het homescreen van je
                      telefoon toevoegen. <br />
                      <br /> Voor android: <br /> Ga naar de website die u op
                      het startscherm wilt plaatsen. Tik rechtsboven op een
                      pictogram met drie stipjes. Tik op "Toevoegen aan
                      startscherm". Geef de site een herkenbare titel of laat de
                      standaardbenaming staan.
                      <br />
                      <br />
                      Voor IOS:
                      <br /> Tik terwijl de website is geopend op de deelknop in
                      de menubalk. Scrol omlaag in de lijst met opties en tik
                      vervolgens op 'Zet op beginscherm'. Geef de site een
                      herkenbare titel of laat de standaardbenaming staan.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Desktop__row ">
          <div className="Desktop__footer">
            <Logo colorMode={state.theme.theme}></Logo>
            <div className="Desktop__footer__text">
              Breng mij naar{" "}
              <a href="http://www.mline.nl" className="Desktop__footer__link">
                mline.nl
              </a>{" "}
              - 2023 M line
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
