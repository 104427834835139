import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerUser, setErrorMessage } from "../../context/user/actions";
import { useGlobalContext } from "../../context/store";
import "./Register.scss";
import { signInWithGoogle } from "../../firebase-config";
import { TosModal } from "../../Views/Tos/Tos";
import ContentBlock from "../ContentBlock/ContentBlock";
import { Eye, EyeSlash } from "react-bootstrap-icons";

function Register() {
  const [state, dispatch] = useGlobalContext();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const register = () => {
    if (!name) {
      dispatch(setErrorMessage("Er is geen naam ingevuld."));
      return;
    }
    if (!passwordInput) {
      dispatch(setErrorMessage("Er is geen wachtwoord ingevuld."));
      return;
    }
    TosModal()
      .then(() => {
        dispatch(registerUser(name, email, passwordInput));
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!state.user.loading && state.user.profile) navigate("/home");
  }, [state.user, navigate]);

  return (
    <div className="Register">
      <div className="Register__container">
        <ContentBlock
          title="Registreren"
          copy="Creëer hier je account om direct gebruik te kunnen maken van de Mline slaapcoach."
        />
        <input
          type="text"
          className="Register__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Je volledige naam"
        />
        <input
          type="text"
          className="Register__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Je e-mailadres"
        />
        <div className="Register__inputGroup">
          <input
            className="Register__textBox"
            //value={password}
            //onChange={(e) => setPassword(e.target.value)}
            type={passwordType}
            onChange={handlePasswordChange}
            value={passwordInput}
            placeholder="Je wachtwoord"
          />
          <div className="Register__toggleBtn">
            <button
              className="btn btn-outline-primary"
              onClick={togglePassword}
            >
              {passwordType === "password" ? <Eye /> : <EyeSlash />}
            </button>
          </div>
        </div>
        {state.user.error && (
          <div className="Form__errorField">{state.user.error}</div>
        )}
        <button className="Register__btn" onClick={register}>
          Aanmelden
        </button>
        <button
          className="Register__btn Register__google Form__google"
          onClick={signInWithGoogle}
        >
          <div className="Google__logo"></div>
          <span>Of meld je aan met Google</span>
        </button>

        <div>
          Heb je al een account?{" "}
          <Link
            to="/login"
            onClick={() => {
              dispatch(setErrorMessage(""));
            }}
          >
            Log nu in.
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Register;
