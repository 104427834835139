import { localStorage } from "../localStorage/localStorage";
import { SWITCHING_THEME } from "./actions";
import initialState from "./state";

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SWITCHING_THEME:
      localStorage.currentTheme.set(payload);
      return { theme: payload };
    default:
      return state;
  }
};

export default reducer;
