import "./Button.scss";
import React, { useState } from "react";
function Button(props) {
  function onClick(arg) {
    if (typeof props.onClick === "function") props.onClick(arg);
  }

  const [buttonStyle, setButtonStyle] = useState(props.buttonStyle);

  return (
    <button
      disabled={props.disabled}
      className={`Button Button--${buttonStyle || "orange"} ${
        props.className || ""
      }`}
      onClick={onClick.bind(this, setButtonStyle)}
    >
      {props.copy}
    </button>
  );
}

export default Button;
