import "./HomeModal.scss";
import { create } from "react-modal-promise";
import React, { useState } from "react";
import ShareIcon from "../../assets/icons/share-icon.svg";

const visibilityChange = (onResolve) => {
  document.onvisibilitychange = () => {
    if (document.visibilityState === "hidden") {
      onResolve();
    }
  };
};

function HomeModal({ isOpen, onResolve }) {
  visibilityChange(onResolve);
  if (!isOpen) return <></>;

  return (
    <div className="HomeModal">
      <div className="HomeModal__text">
        <div className="HomeModal__text__title">
          Installeer deze webapp op je iPhone:
        </div>
        <div className="HomeModal__text__nobreak">
          tap op <img className="HomeModal__shareIcon" src={ShareIcon} /> en
          vervolgens op "Zet op beginscherm"
        </div>
      </div>
    </div>
  );
}

const AddToHomeModal = create(HomeModal);

export default AddToHomeModal;
