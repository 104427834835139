import { ReactComponent as LogoSvg } from "../../assets/images/logo.svg";
import "./Logo.scss";

function Logo(props) {
  return (
    <div className={`Logo Logo--${props.colorMode}`}>
      <LogoSvg />
    </div>
  );
}

export default Logo;
