export class Timer {
  constructor(seconds) {
    this.duration = seconds;
    return this;
  }

  _tick = () => {
    const currentTime = new Date().getTime();

    if (currentTime >= this.startTime + (this.duration * 1000)) {
      if (typeof this.onComplete === 'function') this.onComplete();
      return this.destroy();
    }

    const secondsRemaining = ((this.startTime + this.duration * 1000) - currentTime) / 1000;
    const minutesRemaining = Math.floor(secondsRemaining / 60);

    const remaining = {
      totalSeconds: secondsRemaining,
      time: {
        minutes: minutesRemaining,
        seconds: Math.ceil(secondsRemaining % 60),
      }
    }

    if (typeof this.onTick === 'function') this.onTick(remaining);

  }

  start = () => {
    if (this.interval) return this;
    this.startTime = new Date().getTime();
    this.interval = setInterval(this._tick, 1000);
    return this;
  }

  stop = () => {
    clearInterval(this.interval);
    this.interval = null;
    const currentTime = new Date().getTime();
    this.duration = ((this.startTime + this.duration * 1000) - currentTime) / 1000;
  }

  destroy = () => {
    clearInterval(this.interval);
    return;
  }
}
